// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDinRATKR7O6Fws_Uj4L5a8OzZ02BWxAN8",
  authDomain: "homework-tracker-6a7d3.firebaseapp.com",
  databaseURL: "https://homework-tracker-6a7d3-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "homework-tracker-6a7d3",
  storageBucket: "homework-tracker-6a7d3.appspot.com",
  messagingSenderId: "590730164514",
  appId: "1:590730164514:web:605762d39fdf6e7970cd7b"
};

// Firebaseの初期化
const app = initializeApp(firebaseConfig);

// Realtime Databaseの初期化
export const database = getDatabase(app);

// 必要に応じてappをエクスポート
export default app;
