import { useState, useEffect } from 'react';
import { ref, onValue } from 'firebase/database';
import { database } from '../config/firebase';
import PropTypes from 'prop-types';

export const useUnreadNotesCount = (currentUser, selectedStudent) => {
  const [unreadCount, setUnreadCount] = useState(0);

  useEffect(() => {
    if (!currentUser || !selectedStudent) {
      setUnreadCount(0);
      console.log('No currentUser or selectedStudent. Unread count set to 0.');
      return;
    }

    const notesRef = ref(database, `notes/${selectedStudent.id}`);
    const readTimestampRef = ref(
      database,
      `readTimestamps/teachers/${currentUser.id}/${selectedStudent.id}`
    );

    // readTimestampsの変更も監視する
    const unsubscribeReadTimestamp = onValue(readTimestampRef, (readSnapshot) => {
      const readData = readSnapshot.val();
      const lastRead = readData?.lastRead || 0;
      console.log('Read Timestamp Updated:', lastRead);

      // notesの監視（readTimestampが更新されたときにも再計算）
      const unsubscribeNotes = onValue(notesRef, (notesSnapshot) => {
        const data = notesSnapshot.val();
        const notesArray = data
          ? Object.entries(data).map(([id, note]) => ({ id, ...note }))
          : [];
        console.log('Fetched Notes:', notesArray);

        // 未読数の計算
        const count = notesArray.filter(
          (note) => note.timestamp > lastRead && note.authorId !== currentUser.id
        ).length;
        
        console.log('Calculated Unread Count:', count, 'Last Read:', lastRead);
        setUnreadCount(count);
      });

      // notesの監視を解除する関数を返す
      return () => {
        unsubscribeNotes();
      };
    });

    // クリーンアップ時に両方の監視を解除
    return () => {
      unsubscribeReadTimestamp();
    };
  }, [currentUser, selectedStudent]);

  console.log('Current Unread Notes Count:', unreadCount);
  return { unreadNotesCount: unreadCount };
};

useUnreadNotesCount.propTypes = {
  currentUser: PropTypes.object.isRequired,
  selectedStudent: PropTypes.object.isRequired,
};