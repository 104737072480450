// src/hooks/useUnreadMessages.js

import { useState, useEffect } from 'react';
import { ref, onValue, off } from 'firebase/database'; // 'firebase/database' から直接インポート
import { database } from '../config/firebase'; // 修正後のパス

/**
 * 現在のユーザーに対する未読メッセージ数を取得するフック
 * @param {Object} currentUser - 現在のユーザーオブジェクト
 * @returns {Object} { getUnreadCount } - 特定の会話IDに対する未読メッセージ数を取得する関数
 */
const useUnreadMessages = (currentUser) => {
  const [unreadCounts, setUnreadCounts] = useState({}); // { conversationId: count }

  useEffect(() => {
    if (!currentUser) {
      setUnreadCounts({});
      return;
    }

    const messagesRef = ref(database, 'messages');

    const handleMessages = (snapshot) => {
      const conversations = snapshot.val();
      const counts = {};

      if (conversations) {
        Object.entries(conversations).forEach(([conversationId, messages]) => {
          Object.values(messages).forEach((message) => {
            if (message.receiverId === currentUser.id && message.read === false) {
              if (counts[conversationId]) {
                counts[conversationId] += 1;
              } else {
                counts[conversationId] = 1;
              }
            }
          });
        });
      }

      setUnreadCounts(counts);
    };

    onValue(messagesRef, handleMessages);

    // クリーンアップ
    return () => {
      off(messagesRef, 'value', handleMessages);
    };
  }, [currentUser]);

  /**
   * 特定の会話IDに対する未読メッセージ数を取得する関数
   * @param {string} conversationId - 会話のID
   * @returns {number} 未読メッセージの数
   */
  const getUnreadCount = (conversationId) => {
    return unreadCounts[conversationId] || 0;
  };

  return { getUnreadCount };
};

export default useUnreadMessages;
