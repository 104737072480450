import React from 'react';
import HomeworkTracker from './HomeworkTracker';

function App() {
  return (
    <div className="App">
      <HomeworkTracker />
    </div>
  );
}

export default App;

