import React, { useState, useEffect, useRef } from 'react';
import { X, Trash2 } from 'lucide-react';
import { database } from './config/firebase';
import { ref, onValue, push, set, remove, update, get } from 'firebase/database';
import ConfirmDialog from './ConfirmDialog';
import PropTypes from 'prop-types';

const TeacherNotesModal = ({ selectedStudent, currentUser, onClose }) => {
  const [notes, setNotes] = useState([]);
  const [newNoteContent, setNewNoteContent] = useState('');
  const [noteToDelete, setNoteToDelete] = useState(null);
  const [confirmDialog, setConfirmDialog] = useState(null);
  const notesEndRef = useRef(null);
  const readTimestampRef = useRef(null);

  useEffect(() => {
    if (!selectedStudent) return;

    const notesRefPath = `notes/${selectedStudent.id}`;
    const notesRef = ref(database, notesRefPath);

    // 最新のメモのタイムスタンプを取得して即座に既読として記録
    const updateReadTimestamp = async () => {
      try {
        const snapshot = await get(notesRef);
        const data = snapshot.val();
        if (!data) return;

        const notesArray = Object.values(data);
        const latestTimestamp = notesArray.reduce(
          (max, note) => (note.timestamp > max ? note.timestamp : max),
          0
        );

        readTimestampRef.current = ref(
          database,
          `readTimestamps/teachers/${currentUser.id}/${selectedStudent.id}`
        );

        await update(readTimestampRef.current, { lastRead: latestTimestamp });
        console.log('Initial read timestamp updated:', latestTimestamp);
      } catch (error) {
        console.error('Error updating read timestamp:', error);
      }
    };

    updateReadTimestamp();

    const unsubscribe = onValue(notesRef, (snapshot) => {
      const data = snapshot.val();
      const notesArray = data
        ? Object.entries(data).map(([id, note]) => ({ id, ...note }))
        : [];
      
      console.log('Fetched Notes:', notesArray);
      setNotes(notesArray);

      // メモが追加されたときにも既読を更新
      if (notesArray.length > 0) {
        const latestTimestamp = notesArray.reduce(
          (max, note) => (note.timestamp > max ? note.timestamp : max),
          0
        );

        if (readTimestampRef.current) {
          update(readTimestampRef.current, { lastRead: latestTimestamp })
            .catch((error) => {
              console.error('Error updating read timestamp:', error);
            });
        }
      }
    });

    return () => {
      unsubscribe();
      readTimestampRef.current = null;
    };
  }, [selectedStudent, currentUser.id]);

  useEffect(() => {
    scrollToBottom();
  }, [notes]);

  const scrollToBottom = () => {
    notesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleAddNote = () => {
    if (newNoteContent.trim() === '') return;

    const notesRef = ref(database, `notes/${selectedStudent.id}`);
    const newNoteRef = push(notesRef);
    const timestamp = Date.now();

    set(newNoteRef, {
      content: newNoteContent,
      timestamp: timestamp,
      authorId: currentUser.id,
      authorName: currentUser.name,
    })
      .then(() => {
        console.log('メモが追加されました');
        setNewNoteContent('');
      })
      .catch((error) => {
        console.error('メモの追加に失敗しました:', error);
        setConfirmDialog({
          title: 'エラー',
          message: 'メモの追加に失敗しました。再度お試しください。',
          onConfirm: () => setConfirmDialog(null),
          onCancel: () => setConfirmDialog(null),
          confirmButtonText: 'OK',
          cancelButtonText: null,
        });
      });
  };

  const handleDeleteNote = (noteId) => {
    const noteRef = ref(database, `notes/${selectedStudent.id}/${noteId}`);
    remove(noteRef)
      .then(() => {
        console.log('メモが削除されました');
        setConfirmDialog({
          title: '完了',
          message: 'メモを削除しました。',
          onConfirm: () => setConfirmDialog(null),
          onCancel: () => setConfirmDialog(null),
          confirmButtonText: 'OK',
          cancelButtonText: null,
        });
      })
      .catch((error) => {
        console.error('メモの削除に失敗しました:', error);
        setConfirmDialog({
          title: 'エラー',
          message: 'メモの削除に失敗しました。再度お試しください。',
          onConfirm: () => setConfirmDialog(null),
          onCancel: () => setConfirmDialog(null),
          confirmButtonText: 'OK',
          cancelButtonText: null,
        });
      });
  };

  const handleBulkDelete = () => {
    setConfirmDialog({
      title: '全メモの削除',
      message: `${selectedStudent.name}の全てのメモを削除してもよろしいですか？\nこの操作は取り消せません。`,
      onConfirm: () => {
        const notesRef = ref(database, `notes/${selectedStudent.id}`);
        remove(notesRef)
          .then(() => {
            console.log('全メモが削除されました');
            setConfirmDialog({
              title: '完了',
              message: '全てのメモを削除しました。',
              onConfirm: () => setConfirmDialog(null),
              onCancel: () => setConfirmDialog(null),
              confirmButtonText: 'OK',
              cancelButtonText: null,
            });
          })
          .catch((error) => {
            console.error('全メモの削除に失敗しました:', error);
            setConfirmDialog({
              title: 'エラー',
              message: 'メモの一括削除に失敗しました。再度お試しください。',
              onConfirm: () => setConfirmDialog(null),
              onCancel: () => setConfirmDialog(null),
              confirmButtonText: 'OK',
              cancelButtonText: null,
            });
          });
      },
      onCancel: () => setConfirmDialog(null),
      confirmButtonText: '全て削除',
      cancelButtonText: 'キャンセル',
    });
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const options = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    };
    return date.toLocaleString('ja-JP', options);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-purple-50 w-full max-w-md rounded-lg shadow-lg p-4 flex flex-col">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold text-purple-900">
            他の先生への連絡事項 - {selectedStudent.name}
          </h2>
          <div className="flex items-center gap-2">
            {currentUser.isMaster && notes.length > 0 && (
              <button
                onClick={handleBulkDelete}
                className="text-purple-400 hover:text-red-500 p-2 rounded-full hover:bg-purple-100 transition-colors"
                aria-label="全メモを削除"
                title="全メモを削除"
              >
                <Trash2 size={20} />
              </button>
            )}
            <button 
              onClick={onClose}
              className="text-purple-400 hover:text-purple-600"
              aria-label="閉じる"
            >
              <X size={24} />
            </button>
          </div>
        </div>

        <div className="flex-1 mb-4 overflow-y-auto max-h-80 p-2 bg-yellow-50 rounded">
          {notes.length === 0 ? (
            <p className="text-purple-600 text-center py-4">メモはまだありません。</p>
          ) : (
            notes.map((note) => (
              <div key={note.id} className="p-3 border border-purple-100 rounded mb-2 bg-white">
                <div className="flex justify-between items-start">
                  <p className="whitespace-pre-wrap break-words flex-1 mr-2 text-purple-900">{note.content}</p>
                  {(note.authorId === currentUser.id || currentUser.isMaster) && (
                    <button
                      onClick={() => setNoteToDelete(note)}
                      className="text-purple-400 hover:text-red-500 p-1 rounded-full hover:bg-purple-100 transition-colors flex-shrink-0"
                      aria-label="メモを削除"
                      title="メモを削除"
                    >
                      <Trash2 size={16} />
                    </button>
                  )}
                </div>
                <div className="text-sm text-purple-600 flex justify-between mt-2">
                  <span>{formatTimestamp(note.timestamp)}</span>
                  <span>by {note.authorName}</span>
                </div>
              </div>
            ))
          )}
          <div ref={notesEndRef} />
        </div>

        <div>
          <textarea
            value={newNoteContent}
            onChange={(e) => setNewNoteContent(e.target.value)}
            placeholder="新しいメモを追加"
            className="w-full p-2 border border-purple-200 rounded mb-2 resize-vertical bg-white focus:ring-2 focus:ring-purple-200 focus:border-purple-300"
            rows="3"
            aria-label="新しいメモの入力"
          />
          <button
            onClick={handleAddNote}
            disabled={!newNoteContent.trim()}
            className={`w-full py-2 rounded transition-colors ${
              newNoteContent.trim()
                ? 'bg-purple-500 text-white hover:bg-purple-600'
                : 'bg-purple-200 text-purple-400 cursor-not-allowed'
            }`}
            aria-label="メモを追加"
          >
            メモを追加
          </button>
        </div>

        {noteToDelete && (
          <ConfirmDialog
            title="メモの削除"
            message={`「${noteToDelete.content}」を削除してもよろしいですか？`}
            onConfirm={() => {
              handleDeleteNote(noteToDelete.id);
              setNoteToDelete(null);
            }}
            onCancel={() => setNoteToDelete(null)}
            confirmButtonText="削除"
            cancelButtonText="キャンセル"
          />
        )}

        {confirmDialog && (
          <ConfirmDialog
            title={confirmDialog.title}
            message={confirmDialog.message}
            onConfirm={confirmDialog.onConfirm}
            onCancel={confirmDialog.onCancel}
            confirmButtonText={confirmDialog.confirmButtonText}
            cancelButtonText={confirmDialog.cancelButtonText}
          />
        )}
      </div>
    </div>
  );
};

TeacherNotesModal.propTypes = {
  selectedStudent: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default TeacherNotesModal;