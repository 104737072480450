import React, { useState, useEffect, useRef } from 'react';
import { X, Trash2 } from 'lucide-react';
import { database } from './config/firebase';
import { ref, push, onValue, off, set, remove, update } from 'firebase/database';
import ConfirmDialog from './ConfirmDialog';

const MessagingModal = ({ currentUser, partner, onClose }) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [messageToDelete, setMessageToDelete] = useState(null);
  const [confirmDeleteAll, setConfirmDeleteAll] = useState(false);
  const messagesEndRef = useRef(null);

  // 会話IDの生成
  const conversationId = currentUser.id < partner.id
    ? `${currentUser.id}_${partner.id}`
    : `${partner.id}_${currentUser.id}`;

  // メッセージを取得および既読に更新
  useEffect(() => {
    const messagesRef = ref(database, `messages/${conversationId}`);

    const handleMessages = (snapshot) => {
      const data = snapshot.val();
      if (data) {
        // メッセージを時刻でソート
        const sortedMessages = Object.entries(data)
          .map(([key, value]) => ({ id: key, ...value }))
          .sort((a, b) => a.timestamp - b.timestamp);
        setMessages(sortedMessages);

        // 未読メッセージを既読に更新
        const updates = {};
        sortedMessages.forEach((msg) => {
          if (msg.receiverId === currentUser.id && msg.read === false) {
            updates[`messages/${conversationId}/${msg.id}/read`] = true;
          }
        });
        if (Object.keys(updates).length > 0) {
          update(ref(database), updates).catch((error) => {
            console.error('未読メッセージの更新に失敗しました:', error);
          });
        }
      } else {
        setMessages([]);
      }
    };

    onValue(messagesRef, handleMessages);

    // クリーンアップ
    return () => {
      off(messagesRef, 'value', handleMessages);
    };
  }, [conversationId, currentUser.id]);

  // メッセージを送信
  const sendMessage = () => {
    if (newMessage.trim() === '') return;
    const messagesRef = ref(database, `messages/${conversationId}`);
    const messageRef = push(messagesRef);
    set(messageRef, {
      senderId: currentUser.id,
      receiverId: partner.id,
      content: newMessage,
      timestamp: Date.now(),
      read: false,
    });
    setNewMessage('');
  };

  // メッセージ削除の確認ダイアログを開く
  const confirmDeleteMessage = (message) => {
    setMessageToDelete(message);
  };

  // メッセージを削除
  const deleteMessage = () => {
    if (messageToDelete) {
      const messageRef = ref(
        database,
        `messages/${conversationId}/${messageToDelete.id}`
      );
      remove(messageRef)
        .then(() => {
          console.log('メッセージが削除されました');
          setMessageToDelete(null);
        })
        .catch((error) => {
          console.error('メッセージの削除に失敗しました:', error);
          alert('メッセージの削除に失敗しました。再度お試しください。');
          setMessageToDelete(null);
        });
    }
  };

  // 全メッセージを削除
  const deleteAllMessages = () => {
    const messagesRef = ref(database, `messages/${conversationId}`);
    remove(messagesRef)
      .then(() => {
        console.log('全てのメッセージが削除されました');
        setConfirmDeleteAll(false);
      })
      .catch((error) => {
        console.error('全てのメッセージの削除に失敗しました:', error);
        alert('全てのメッセージの削除に失敗しました。再度お試しください。');
        setConfirmDeleteAll(false);
      });
  };

  // タイムスタンプをフォーマットする関数
  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const options = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    };
    return date.toLocaleString('ja-JP', options);
  };

  // メッセージリストを自動スクロール
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-purple-50 w-full max-w-md rounded-lg shadow-lg p-4 flex flex-col">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold text-purple-900 flex items-center">
            塾長と会話 - {partner.name}
            {currentUser.role === 'teacher' && (
              <button
                onClick={() => setConfirmDeleteAll(true)}
                className="text-purple-400 hover:text-red-500 ml-2"
                aria-label="全てのメッセージを削除"
              >
                <Trash2 size={20} />
              </button>
            )}
          </h2>
          <button onClick={onClose} className="text-purple-400 hover:text-purple-600" aria-label="閉じる">
            <X size={24} />
          </button>
        </div>
        <div className="flex-1 mb-4 overflow-y-auto max-h-80 p-2 bg-yellow-50 rounded">
          {messages.map((msg) => (
            <div
              key={msg.id}
              className={`mb-2 flex ${
                msg.senderId === currentUser.id ? 'justify-end' : 'justify-start'
              }`}
            >
              <div className="max-w-xs">
                <div
                  className={`p-2 rounded ${
                    msg.senderId === currentUser.id
                      ? 'bg-purple-100'
                      : 'bg-yellow-100'
                  }`}
                >
                  <div className="text-sm break-words text-purple-900">{msg.content}</div>
                  <div className="flex items-center justify-end mt-1 gap-2">
                    <div className="text-xs text-purple-600">
                      {formatTimestamp(msg.timestamp)}
                    </div>
                    {msg.senderId === currentUser.id && (
                      <button
                        onClick={() => confirmDeleteMessage(msg)}
                        className="text-purple-400 hover:text-red-500"
                        aria-label="メッセージを削除"
                      >
                        <Trash2 size={16} />
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div ref={messagesEndRef}></div>
        </div>
        <div className="flex items-center">
          <textarea
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            className="flex-1 border border-purple-200 rounded p-2 mr-2 bg-white focus:ring-2 focus:ring-purple-200 focus:border-purple-300"
            placeholder="メッセージを入力"
            rows={3}
          />
          <button
            onClick={sendMessage}
            className="bg-purple-500 hover:bg-purple-600 text-white px-4 py-2 rounded transition-colors"
            aria-label="送信"
          >
            送信
          </button>
        </div>
      </div>
      {messageToDelete && (
        <ConfirmDialog
          title="メッセージの削除"
          message="このメッセージを削除しますか？"
          onConfirm={deleteMessage}
          onCancel={() => setMessageToDelete(null)}
        />
      )}
      {confirmDeleteAll && (
        <ConfirmDialog
          title="全てのメッセージを削除"
          message="この生徒との全てのメッセージを削除しますか？"
          onConfirm={deleteAllMessages}
          onCancel={() => setConfirmDeleteAll(false)}
        />
      )}
    </div>
  );
};

export default MessagingModal;