// HomeworkTracker.js

import React, { useState, useEffect, useMemo } from 'react';
import ConfirmDialog from './ConfirmDialog';
import useUnreadMessages from './hooks/useUnreadMessages';
// HomeworkTracker.js
import { useUnreadNotesCount } from './hooks/useUnreadNotesCount'; // 名前付きインポート
import { saveAs } from 'file-saver';


import TeacherNotes from './TeacherNotes';
import * as XLSX from 'xlsx';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import {
  PlusCircle,
  X,
  LogOut,
  CheckSquare,
  Square,
  Calendar,
  Edit2,
  UserPlus,
  Menu,
  Edit,
  Upload,
  MessageSquare,
  Edit3,
  FileText
} from 'lucide-react';

import { database } from './config/firebase';

import {
  ref,
  onValue,
  push,
  set,
  remove,
  get,
  update
} from 'firebase/database';
import { v4 as uuidv4 } from 'uuid';
import Linkify from 'linkify-react';
import MessagingModal from './MessagingModal';
import TeacherNotesModal from './TeacherNotesModal';
import PropTypes from 'prop-types';

// フィルターの種類を定義
const FILTERS = [
  { id: 'all', name: '全て表示' },
  { id: 'dueAndNotConfirmed', name: '宿題期限あり＆未確認' },
  { id: 'noTestDate', name: '確認テスト日時未設定' },
  { id: 'failedConfirmation', name: '最終確認が不合格' },
];

// 定数: 科目リスト
const SUBJECTS = [
  { id: 'english', name: '英語' },
  { id: 'math', name: '数学' },
  { id: 'japanese', name: '国語' },
  { id: 'science', name: '理科' },
  { id: 'social', name: '社会' }
];

// 学年リスト
const GRADES = [
  { id: 'shougaku1', name: '小1' },
  { id: 'shougaku2', name: '小2' },
  { id: 'shougaku3', name: '小3' },
  { id: 'shougaku4', name: '小4' },
  { id: 'shougaku5', name: '小5' },
  { id: 'shougaku6', name: '小6' },
  { id: 'chuugaku1', name: '中1' },
  { id: 'chuugaku2', name: '中2' },
  { id: 'chuugaku3', name: '中3' },
  { id: 'koukou1', name: '高1' },
  { id: 'koukou2', name: '高2' },
  { id: 'koukou3', name: '高3' }
];

// 科目名を取得する関数
const getSubjectName = (subjectId) => {
  const subject = SUBJECTS.find((s) => s.id === subjectId);
  return subject ? subject.name : subjectId;
};



// Alertコンポーネント
const Alert = ({ children, variant = 'default', className = '' }) => (
  <div className={`p-4 rounded-lg ${
    variant === 'destructive' ? 'bg-yellow-100 text-yellow-700' : 'bg-purple-100 text-purple-700'
  } ${className}`}>
    {children}
  </div>
);

Alert.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(['default', 'destructive']),
  className: PropTypes.string,
};

// AlertDescriptionコンポーネント
const AlertDescription = ({ children, className = '' }) => (
  <div className={`text-sm ${className}`}>
    {children}
  </div>
);

AlertDescription.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

// BulkTaskImportコンポーネント
const BulkTaskImport = ({
  students,
  onImport,
  selectedStudent,
  selectedSubject, // 追加
  tasksByStudentAndSubject,
}) => {
  const [importing, setImporting] = useState(false);
  const [error, setError] = useState(null);
  const [preview, setPreview] = useState(null);
  const [isDragging, setIsDragging] = useState(false); // ドラッグ中の状態を管理

  // 学年名から学年IDへのマッピング
  const getGradeId = (gradeName) => {
    const grade = GRADES.find((g) => g.name === gradeName.trim());
    return grade ? grade.id : null;
  };

  // CSVデータをパース
  const parseCSV = (text) => {
    const rows = text.split('\n');
    const headers = rows[0].split(',').map((header) => header.trim());

    return rows.slice(1)
      .filter((row) => row.trim())
      .map((row) => {
        const values = row.split(',').map((value) => value.trim());
        return headers.reduce((obj, header, index) => {
          obj[header] = values[index];
          return obj;
        }, {});
      });
  };

  // 科目IDの取得
  const getSubjectId = (subjectName) => {
    const subjectMap = {
      '数学': 'math',
      '英語': 'english',
      '国語': 'japanese',
      '理科': 'science',
      '社会': 'social',
    };
    return subjectMap[subjectName.trim()];
  };

  // 生徒IDの取得（学年名を学年IDに変換）
  const getStudentId = (studentName, gradeName) => {
    const gradeId = getGradeId(gradeName);
    if (!gradeId) return null; // 無効な学年名の場合

    const student = students.find(
      (s) => s.name.trim() === studentName.trim() && s.grade === gradeId
    );
    return student ? student.id : null;
  };

  // データの検証
  const validateData = (data) => {
    const errors = [];
    const requiredFields = ['生徒名', '学年', '教科', '課題名', 'チェック項目'];

    data.forEach((row, index) => {
      // 各必須フィールドのチェック
      requiredFields.forEach((field) => {
        if (!row[field] || row[field].toString().trim() === '') {
          errors.push(`行 ${index + 2}: ${field}が未入力です`);
        }
      });

      // 教科の有効性チェック
      if (row['教科'] && !getSubjectId(row['教科'])) {
        errors.push(`行 ${index + 2}: 無効な教科名です: ${row['教科']}`);
      }

      // 生徒の存在チェック
      if (row['生徒名'] && row['学年']) {
        const studentId = getStudentId(row['生徒名'], row['学年']);
        if (!studentId) {
          errors.push(`行 ${index + 2}: 生徒が見つかりません: ${row['生徒名']}（${row['学年']}）`);
        }
      }
    });

    return errors;
  };

  // データの整形
  const processData = (data) => {
    const tasksByStudentAndSubject = {};
    let taskOrder = 0;

    // タスクごとにグループ化
    const taskGroups = {};
    data.forEach((row) => {
      const title = row['課題名'].trim();
      if (!taskGroups[title]) {
        taskGroups[title] = [];
      }
      taskGroups[title].push(row);
    });

    // タスクタイトルの順序を維持
    const orderedTitles = data
      .map((row) => row['課題名'].trim())
      .filter((title, index, self) => self.indexOf(title) === index);

    orderedTitles.forEach((title) => {
      const rows = taskGroups[title];
      let itemOrder = 0; // 各タスクごとにorderをリセット

      rows.forEach((row) => {
        const studentId = getStudentId(row['生徒名'], row['学年']);
        const subjectId = getSubjectId(row['教科']);
        const key = `${studentId}-${subjectId}`;

        if (!tasksByStudentAndSubject[key]) {
          tasksByStudentAndSubject[key] = { tasks: {} };
        }

        // 既存のタスクをタイトルで検索
        const existingTask = Object.values(tasksByStudentAndSubject[key].tasks).find(
          (task) => task.title === title
        );

        if (existingTask) {
          const itemId = uuidv4();
          existingTask.items[itemId] = {
            id: itemId,
            text: row['チェック項目'].trim(),
            order: itemOrder++, // 各タスク内で順序を設定
            dueDate: row['課題期限'] || '',
            testDate: row['確認テスト日時'] || '',
            testScore: row['確認テストスコア'] || '',
            checked: row['チェックボックス']?.toString().toLowerCase() === 'true',
          };
        } else {
          const taskId = uuidv4();
          const itemId = uuidv4();
          tasksByStudentAndSubject[key].tasks[taskId] = {
            id: taskId,
            title: title,
            order: taskOrder++, // タスクの順序を設定
            items: {
              [itemId]: {
                id: itemId,
                text: row['チェック項目'].trim(),
                order: itemOrder++, // 最初の項目の順序を設定
                dueDate: row['課題期限'] || '',
                testDate: row['確認テスト日時'] || '',
                testScore: row['確認テストスコア'] || '',
                checked: row['チェックボックス']?.toString().toLowerCase() === 'true',
              },
            },
          };
        }
      });
    });

    return tasksByStudentAndSubject;
  };

  // ファイルの解析とデータの取得
  const parseFile = (file, callback) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = e.target.result;
      let jsonData = [];

      if (file.type === 'text/csv' || file.name.endsWith('.csv')) {
        // CSVファイルの解析
        jsonData = parseCSV(data);
      } else if (
        file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
        file.type === 'application/vnd.ms-excel'
      ) {
        // エクセルファイルの解析
        const workbook = XLSX.read(data, { type: 'binary' });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        jsonData = XLSX.utils.sheet_to_json(worksheet, { defval: '' });
      }

      callback(jsonData);
    };

    if (file.type === 'text/csv' || file.name.endsWith('.csv')) {
      reader.readAsText(file);
    } else {
      reader.readAsBinaryString(file);
    }
  };

  const handleFileUpload = (file) => {
    if (!file) return;

    const allowedTypes = [
      'text/csv',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-excel',
    ];
    const isValidType =
      allowedTypes.includes(file.type) ||
      ['.csv', '.xlsx', '.xls'].some((ext) => file.name.endsWith(ext));
    if (!isValidType) {
      setError('CSVまたはエクセルファイルのみアップロード可能です');
      return;
    }

    setImporting(true);
    setError(null);
    setPreview(null);

    parseFile(file, (data) => {
      try {
        const validationErrors = validateData(data);
        if (validationErrors.length > 0) {
          setError(validationErrors.join('\n'));
          return;
        }

        const processedData = processData(data);
        setPreview({
          totalTasks: Object.values(processedData).reduce(
            (sum, { tasks }) => sum + Object.keys(tasks).length,
            0
          ),
          totalItems: Object.values(processedData).reduce(
            (sum, { tasks }) =>
              sum +
              Object.values(tasks).reduce(
                (itemSum, task) => itemSum + Object.keys(task.items).length,
                0
              ),
            0
          ),
        });

        onImport(processedData);
      } catch (error) {
        console.error('Error processing file:', error);
        setError('ファイルの処理中にエラーが発生しました');
      } finally {
        setImporting(false);
      }
    });
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    handleFileUpload(file);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);

    const file = event.dataTransfer.files[0];
    handleFileUpload(file);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };



  return (
    <div
      className={`p-4 bg-white rounded-lg shadow ${
        isDragging ? 'border-yellow-300 bg-yellow-100' : 'border-purple-200'
      }`}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
    >
      <div className="mb-4">
        <h3 className="text-lg font-semibold mb-2">課題の一括登録</h3>
        <p className="text-sm text-gray-600 mb-4">
          CSVまたはエクセルファイルから課題を一括登録できます。
          必要な列: 生徒名, 学年, 教科, 課題名, チェック項目, 課題期限, 確認テスト日時, 確認テストスコア, チェックボックス
        </p>

        <label className="flex flex-col items-center p-4 border-2 border-dashed cursor-pointer rounded-lg transition-colors bg-purple-100 border-purple-300 hover:border-purple-400">
          <Upload className="w-8 h-8 mb-2 text-purple-500" />
          <span className="text-sm text-purple-700">
            CSVまたはエクセルファイルを選択またはドラッグ＆ドロップ
          </span>
          <input
            type="file"
            accept=".csv, .xlsx, .xls"
            onChange={handleFileInputChange}
            className="hidden"
            disabled={importing}
          />
        </label>
      </div>

      {importing && (
        <div className="text-center text-yellow-600">インポート中...</div>
      )}

      {error && (
        <div className="text-yellow-600 mt-4">
          <p className="whitespace-pre-line">{error}</p>
        </div>
      )}

      {preview && (
        <div className="text-purple-600 mt-4">
          <p>{`${preview.totalTasks}件の課題（合計${preview.totalItems}項目）の登録準備が完了しました。`}</p>


        </div>
      )}
    </div>
  );
};

BulkTaskImport.propTypes = {
  students: PropTypes.array.isRequired,
  onImport: PropTypes.func.isRequired,
  selectedStudent: PropTypes.object.isRequired,
  selectedSubject: PropTypes.object.isRequired, // 追加
  tasksByStudentAndSubject: PropTypes.object.isRequired, // 追加
};

// ログインコンポーネント
const Login = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  
  const handleLogin = () => {
    onLogin(username, password);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleLogin();
    }
  };

  return (
    <div className="flex items-center justify-center h-screen bg-yellow-50">
      <div className="bg-white p-6 rounded shadow w-full max-w-md">
        <h2 className="text-2xl font-semibold mb-6 text-center">ログイン</h2>
        <input
          type="text"
          value={username}
          onChange={e => setUsername(e.target.value)}
          placeholder="ユーザー名"
          className="w-full p-3 border rounded mb-4 focus:outline-none focus:ring-2 focus:ring-purple-300"
          onKeyDown={handleKeyDown}
          aria-label="ユーザー名入力"
        />
        <input
          type="password"
          value={password}
          onChange={e => setPassword(e.target.value)}
          placeholder="パスワード"
          className="w-full p-3 border rounded mb-6 focus:outline-none focus:ring-2 focus:ring-purple-300"
          onKeyDown={handleKeyDown}
          aria-label="パスワード入力"
        />
        <button
          onClick={handleLogin}
          className="w-full bg-purple-300 text-purple-800 py-3 rounded hover:bg-purple-400 transition-colors duration-200"
          aria-label="ログインボタン"
        >
          ログイン
        </button>
      </div>
    </div>
  );
};

Login.propTypes = {
  onLogin: PropTypes.func.isRequired,
};

// 生徒リストコンポーネント
const StudentList = ({
  students,
  selectedStudent,
  onSelectStudent,
  onAddStudent,
  onUpdateStudent,
  onDeleteStudent,
  currentUser,
  onOpenMessaging,
  onClose,
}) => {
  const [newStudentData, setNewStudentData] = useState({
    name: '',
    username: '',
    password: '',
    grade: '',
  });

  // 生徒追加フォームの表示状態を管理するステート
  const [isAddingStudent, setIsAddingStudent] = useState(false);

  // 編集中の生徒IDを管理するステート
  const [editingStudentId, setEditingStudentId] = useState(null);

  // 確認ダイアログの状態を管理するステート
  const [confirmDialog, setConfirmDialog] = useState(null);

  // 学年でフィルタリングされた生徒リスト
  const [selectedGrade, setSelectedGrade] = useState('all'); // 学年フィルタ用のステート

  const filteredStudents = students.filter((student) => {
    const matchesGrade = selectedGrade === 'all' || student.grade === selectedGrade;
    return matchesGrade;
  });

  // フックの呼び出しをトップレベルに移動
  const { getUnreadCount } = useUnreadMessages(currentUser);

  return (
    <div className="p-4 flex flex-col h-full">
      {/* 学年フィルタリング */}
      <div className="mb-4">
        <select
          value={selectedGrade}
          onChange={(e) => setSelectedGrade(e.target.value)}
          className="w-full border rounded p-2 bg-yellow-100 text-yellow-700"
          aria-label="学年でフィルタリング"
        >
          <option value="all">すべての学年</option>
          {GRADES.map((grade) => (
            <option key={grade.id} value={grade.id}>
              {grade.name}
            </option>
          ))}
        </select>
      </div>

      {/* 生徒の追加トグルボタン */}
      <div className="mb-4">
        {!isAddingStudent ? (
          <button
            onClick={() => setIsAddingStudent(true)}
            className="w-full bg-yellow-300 text-yellow-800 px-4 py-2 rounded hover:bg-yellow-400 flex items-center justify-center gap-2 transition-colors duration-200"
            aria-label="生徒追加フォームを開く"
          >
            <UserPlus size={20} />
            生徒を追加
          </button>
        ) : (
          <div className="mb-4">
            <h3 className="font-semibold text-lg mb-2">生徒の追加</h3>
            <input
              type="text"
              value={newStudentData.name}
              onChange={(e) => setNewStudentData({ ...newStudentData, name: e.target.value })}
              placeholder="生徒名"
              className="w-full p-2 border rounded mb-2 focus:outline-none focus:ring-2 focus:ring-purple-300"
              aria-label="生徒名入力"
            />
            <input
              type="text"
              value={newStudentData.username}
              onChange={(e) => setNewStudentData({ ...newStudentData, username: e.target.value })}
              placeholder="ユーザー名"
              className="w-full p-2 border rounded mb-2 focus:outline-none focus:ring-2 focus:ring-purple-300"
              aria-label="ユーザー名入力"
            />
            <input
              type="password"
              value={newStudentData.password}
              onChange={(e) => setNewStudentData({ ...newStudentData, password: e.target.value })}
              placeholder="パスワード"
              className="w-full p-2 border rounded mb-2 focus:outline-none focus:ring-2 focus:ring-purple-300"
              aria-label="パスワード入力"
            />
            <select
              value={newStudentData.grade}
              onChange={(e) => setNewStudentData({ ...newStudentData, grade: e.target.value })}
              className="w-full border rounded p-2 mb-4 bg-purple-100 text-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-300"
              aria-label="学年を選択"
            >
              <option value="">学年を選択</option>
              {GRADES.map((grade) => (
                <option key={grade.id} value={grade.id}>
                  {grade.name}
                </option>
              ))}
            </select>
            <div className="flex justify-end space-x-2">
              <button
                onClick={() => {
                  // 入力の検証（簡易）
                  if (!newStudentData.name || !newStudentData.username || !newStudentData.password || !newStudentData.grade) {
                    alert('すべての項目を入力してください');
                    return;
                  }
                  onAddStudent(newStudentData);
                  setNewStudentData({ name: '', username: '', password: '', grade: '' });
                  setIsAddingStudent(false);
                }}
                className="bg-purple-300 text-purple-800 px-4 py-2 rounded hover:bg-purple-400"
                aria-label="生徒追加保存ボタン"
              >
                追加
              </button>
              <button
                onClick={() => {
                  setNewStudentData({ name: '', username: '', password: '', grade: '' });
                  setIsAddingStudent(false);
                }}
                className="bg-purple-100 text-purple-700 px-4 py-2 rounded hover:bg-purple-200"
                aria-label="生徒追加キャンセルボタン"
              >
                キャンセル
              </button>
            </div>
          </div>
        )}
      </div>

      {/* 生徒一覧の表示部分 */}
      <div className="flex-1 overflow-y-auto space-y-2">
        {filteredStudents.map((student) => {
          const conversationId =
            currentUser.id < student.id
              ? `${currentUser.id}_${student.id}`
              : `${student.id}_${currentUser.id}`;
          const studentUnreadCount = getUnreadCount(conversationId);
          const gradeName =
            GRADES.find((grade) => grade.id === student.grade)?.name || '';

          const isEditing = editingStudentId === student.id;

          return (
            <div
              key={student.id}
              className={`p-3 rounded-lg transition-colors duration-200 ${
                selectedStudent?.id === student.id
                  ? 'bg-yellow-100'
                  : 'hover:bg-purple-100'
              } flex items-center justify-between`}
              aria-label={`生徒: ${student.name}`}
            >
              {isEditing ? (
                <div className="w-full">
                  <input
                    type="text"
                    value={newStudentData.name}
                    onChange={(e) => setNewStudentData({ ...newStudentData, name: e.target.value })}
                    placeholder="生徒名"
                    className="w-full p-1.5 text-sm border rounded mb-1.5 focus:outline-none focus:ring-1 focus:ring-purple-300"
                    aria-label="生徒名編集入力"
                  />
                  <input
                    type="text"
                    value={newStudentData.username}
                    onChange={(e) => setNewStudentData({ ...newStudentData, username: e.target.value })}
                    placeholder="ユーザー名"
                    className="w-full p-1.5 text-sm border rounded mb-1.5 focus:outline-none focus:ring-1 focus:ring-purple-300"
                    aria-label="ユーザー名編集入力"
                  />
                  <input
                    type="password"
                    value={newStudentData.password}
                    onChange={(e) => setNewStudentData({ ...newStudentData, password: e.target.value })}
                    placeholder="パスワード"
                    className="w-full p-1.5 text-sm border rounded mb-1.5 focus:outline-none focus:ring-1 focus:ring-purple-300"
                    aria-label="パスワード編集入力"
                  />
                  <select
                    value={newStudentData.grade}
                    onChange={(e) => setNewStudentData({ ...newStudentData, grade: e.target.value })}
                    className="w-full p-1.5 text-sm border rounded mb-2 focus:outline-none focus:ring-1 focus:ring-purple-300 bg-purple-100 text-purple-700"
                    aria-label="学年編集選択"
                  >
                    <option value="">学年を選択</option>
                    {GRADES.map((grade) => (
                      <option key={grade.id} value={grade.id}>
                        {grade.name}
                      </option>
                    ))}
                  </select>
                  <div className="flex justify-end space-x-1">
                    <button
                      onClick={() => {
                        if (!newStudentData.name || !newStudentData.username || !newStudentData.password || !newStudentData.grade) {
                          alert('すべての項目を入力してください');
                          return;
                        }
                        onUpdateStudent({ ...student, ...newStudentData });
                        setEditingStudentId(null);
                        setNewStudentData({ name: '', username: '', password: '', grade: '' });
                      }}
                      className="min-w-[60px] text-sm whitespace-nowrap bg-yellow-300 text-yellow-800 px-2 py-1.5 rounded hover:bg-yellow-400"
                      aria-label="生徒情報保存ボタン"
                    >
                      保存
                    </button>
                    <button
                      onClick={() => {
                        setEditingStudentId(null);
                        setNewStudentData({ name: '', username: '', password: '', grade: '' });
                      }}
                      className="min-w-[60px] text-sm whitespace-nowrap bg-purple-100 text-purple-700 px-2 py-1.5 rounded hover:bg-purple-200"
                      aria-label="生徒情報編集キャンセルボタン"
                    >
                      キャンセル
                    </button>
                    <button
                      onClick={() => {
                        setConfirmDialog({
                          title: '生徒の削除',
                          message: `${student.name}を削除してもよろしいですか？`,
                          onConfirm: () => {
                            onDeleteStudent(student.id);
                            setEditingStudentId(null);
                            setNewStudentData({ name: '', username: '', password: '', grade: '' });
                            setConfirmDialog(null);
                          },
                          onCancel: () => setConfirmDialog(null),
                        });
                      }}
                      className="min-w-[60px] text-sm whitespace-nowrap bg-pink-300 text-pink-800 px-2 py-1.5 rounded hover:bg-pink-400"
                      aria-label="生徒削除ボタン"
                    >
                      削除
                    </button>
                  </div>
                </div>
              ) : (
                <>
                  <div
                    className="flex-1 cursor-pointer"
                    onClick={() => onSelectStudent(student)}
                    aria-label={`生徒選択: ${student.name}`}
                  >
                    <div className="font-medium text-purple-700 hover:text-purple-800">
                      {student.name}（{gradeName}）
                    </div>
                    <div className="text-sm text-purple-600">
                      ユーザー名: {student.username}
                    </div>
                  </div>
                  <div className="flex items-center space-x-2">
                    {/* メッセージボタン */}
                    {currentUser?.isMaster && (
                      <>
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            onOpenMessaging(student);
                          }}
                          className="relative text-purple-500 hover:text-yellow-500"
                          aria-label={`メッセージを送る ${student.name}`}
                        >
                          <MessageSquare size={20} />
                          {studentUnreadCount > 0 && (
                            <span className="absolute top-0 right-0 inline-flex items-center justify-center px-1 py-0.5 text-xs font-bold leading-none text-yellow-100 bg-yellow-600 rounded-full">
                              {studentUnreadCount}
                            </span>
                          )}
                        </button>
                        {/* 編集ボタン */}
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            setEditingStudentId(student.id);
                            setNewStudentData({
                              name: student.name,
                              username: student.username,
                              password: student.password,
                              grade: student.grade,
                            });
                          }}
                          className="text-purple-500 hover:text-purple-700"
                          aria-label={`編集 ${student.name}`}
                        >
                          <Edit3 size={20} />
                        </button>
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
          );
        })}
      </div>

      {/* モバイル表示の場合の閉じるボタン */}
      {onClose && (
        <button
          onClick={onClose}
          className="mt-4 w-full py-2 bg-purple-100 text-purple-700 rounded hover:bg-purple-200"
          aria-label="サイドバーを閉じる"
        >
          閉じる
        </button>
      )}

      {/* 確認ダイアログのレンダリング */}
      {confirmDialog && (
        <ConfirmDialog
          title={confirmDialog.title}
          message={confirmDialog.message}
          onConfirm={confirmDialog.onConfirm}
          onCancel={confirmDialog.onCancel}
        />
      )}
    </div>
  );
};

StudentList.propTypes = {
  students: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      username: PropTypes.string.isRequired,
      password: PropTypes.string.isRequired,
      grade: PropTypes.string.isRequired,
    })
  ).isRequired,
  selectedStudent: PropTypes.object,
  onSelectStudent: PropTypes.func.isRequired,
  onAddStudent: PropTypes.func.isRequired,
  onUpdateStudent: PropTypes.func.isRequired,
  onDeleteStudent: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  onOpenMessaging: PropTypes.func.isRequired,
  onClose: PropTypes.func, // 任意プロパティ
};

// 教師リストコンポーネント（マスター用）
const TeacherList = ({
  teachers,
  selectedTeacher,
  onSelectTeacher,
  onAddTeacher,
  onUpdateTeacher,
  onDeleteTeacher,
  onClose,
  currentUser,
  onOpenMessaging, // これは不要になる場合もあります
}) => {
  const [newTeacherData, setNewTeacherData] = useState({
    name: '',
    username: '',
    password: '',
    isMaster: false,
  });
  const [isAddingTeacher, setIsAddingTeacher] = useState(false);
  const [editingTeacherId, setEditingTeacherId] = useState(null);
  const [confirmDialog, setConfirmDialog] = useState(null);

  // デバッグ用ログ
  useEffect(() => {
    console.log('TeacherList - currentUser:', currentUser);
    console.log('TeacherList - teachers:', teachers);
  }, [currentUser, teachers]);

  // フックの呼び出しをトップレベルに移動
  const { getUnreadCount } = useUnreadMessages(currentUser);

  return (
    <div className="p-4 flex flex-col h-full">
      {/* 教師の追加トグルボタン */}
      <div className="mb-4">
        {!isAddingTeacher ? (
          <button
            onClick={() => setIsAddingTeacher(true)}
            className="w-full bg-yellow-300 text-yellow-800 px-4 py-2 rounded hover:bg-yellow-400 flex items-center justify-center gap-2 transition-colors duration-200"
            aria-label="教師追加フォームを開く"
          >
            <UserPlus size={20} />
            教師を追加
          </button>
        ) : (
          <div className="mb-4">
            <h3 className="font-semibold text-lg mb-2">教師の追加</h3>
            <input
              type="text"
              value={newTeacherData.name}
              onChange={(e) =>
                setNewTeacherData({
                  ...newTeacherData,
                  name: e.target.value,
                })
              }
              placeholder="教師名"
              className="w-full p-2 border rounded mb-2 focus:outline-none focus:ring-2 focus:ring-purple-300"
              aria-label="教師名入力"
            />
            <input
              type="text"
              value={newTeacherData.username}
              onChange={(e) =>
                setNewTeacherData({
                  ...newTeacherData,
                  username: e.target.value,
                })
              }
              placeholder="ユーザー名"
              className="w-full p-2 border rounded mb-2 focus:outline-none focus:ring-2 focus:ring-purple-300"
              aria-label="ユーザー名入力"
            />
            <input
              type="password"
              value={newTeacherData.password}
              onChange={(e) =>
                setNewTeacherData({
                  ...newTeacherData,
                  password: e.target.value,
                })
              }
              placeholder="パスワード"
              className="w-full p-2 border rounded mb-2 focus:outline-none focus:ring-2 focus:ring-purple-300"
              aria-label="パスワード入力"
            />
            <div className="flex items-center mb-4">
              <input
                type="checkbox"
                checked={newTeacherData.isMaster}
                onChange={(e) =>
                  setNewTeacherData({
                    ...newTeacherData,
                    isMaster: e.target.checked,
                  })
                }
                id="isMaster"
                className="mr-2"
                aria-label="マスター権限チェックボックス"
              />
              <label htmlFor="isMaster" className="text-purple-700">
                マスター権限
              </label>
            </div>
            <div className="flex justify-end space-x-2">
              <button
                onClick={() => {
                  // 入力の検証（簡易）
                  if (
                    !newTeacherData.name ||
                    !newTeacherData.username ||
                    !newTeacherData.password
                  ) {
                    alert('すべての項目を入力してください');
                    return;
                  }
                  onAddTeacher(newTeacherData);
                  setNewTeacherData({
                    name: '',
                    username: '',
                    password: '',
                    isMaster: false,
                  });
                  setIsAddingTeacher(false);
                }}
                className="bg-purple-300 text-purple-800 px-4 py-2 rounded hover:bg-purple-400"
                aria-label="教師追加保存ボタン"
              >
                追加
              </button>
              <button
                onClick={() => {
                  setNewTeacherData({
                    name: '',
                    username: '',
                    password: '',
                    isMaster: false,
                  });
                  setIsAddingTeacher(false);
                }}
                className="bg-purple-100 text-purple-700 px-4 py-2 rounded hover:bg-purple-200"
                aria-label="教師追加キャンセルボタン"
              >
                キャンセル
              </button>
            </div>
          </div>
        )}
      </div>

      {/* 教師一覧の表示部分 */}
      <div className="flex-1 overflow-y-auto space-y-2">
        {teachers.map((teacher) => {
          // teacher が未定義の場合はスキップ
          if (!teacher || !teacher.id) {
            return null;
          }

          // 編集状態の確認
          const isEditing = editingTeacherId === teacher.id;

          // 未読メッセージ数の取得
          const conversationId =
            currentUser.id < teacher.id
              ? `${currentUser.id}_${teacher.id}`
              : `${teacher.id}_${currentUser.id}`;
          const teacherUnreadCount = getUnreadCount(conversationId);

          return (
            <div
              key={teacher.id}
              className={`p-3 rounded-lg transition-colors duration-200 ${
                selectedTeacher?.id === teacher.id
                  ? 'bg-yellow-100'
                  : 'hover:bg-purple-100'
              } flex items-center justify-between`}
              aria-label={`教師: ${teacher.name}`}
            >
              {isEditing ? (
                <div className="w-full">
                  <input
                    type="text"
                    value={newTeacherData.name}
                    onChange={(e) =>
                      setNewTeacherData({
                        ...newTeacherData,
                        name: e.target.value,
                      })
                    }
                    placeholder="教師名"
                    className="w-full p-1.5 text-sm border rounded mb-1.5 focus:outline-none focus:ring-1 focus:ring-purple-300"
                    aria-label="教師名編集入力"
                  />
                  <input
                    type="text"
                    value={newTeacherData.username}
                    onChange={(e) =>
                      setNewTeacherData({
                        ...newTeacherData,
                        username: e.target.value,
                      })
                    }
                    placeholder="ユーザー名"
                    className="w-full p-1.5 text-sm border rounded mb-1.5 focus:outline-none focus:ring-1 focus:ring-purple-300"
                    aria-label="ユーザー名編集入力"
                  />
                  <input
                    type="password"
                    value={newTeacherData.password}
                    onChange={(e) =>
                      setNewTeacherData({
                        ...newTeacherData,
                        password: e.target.value,
                      })
                    }
                    placeholder="パスワード"
                    className="w-full p-1.5 text-sm border rounded mb-1.5 focus:outline-none focus:ring-1 focus:ring-purple-300"
                    aria-label="パスワード編集入力"
                  />
                  <div className="flex items-center mb-2">
                    <input
                      type="checkbox"
                      checked={newTeacherData.isMaster}
                      onChange={(e) =>
                        setNewTeacherData({
                          ...newTeacherData,
                          isMaster: e.target.checked,
                        })
                      }
                      id={`edit-isMaster-${teacher.id}`}
                      className="mr-2"
                      aria-label="マスター権限編集チェックボックス"
                    />
                    <label
                      htmlFor={`edit-isMaster-${teacher.id}`}
                      className="text-purple-700"
                    >
                      マスター権限
                    </label>
                  </div>
                  <div className="flex justify-end space-x-1">
                    <button
                      onClick={() => {
                        if (
                          !newTeacherData.name ||
                          !newTeacherData.username ||
                          !newTeacherData.password
                        ) {
                          alert('すべての項目を入力してください');
                          return;
                        }
                        onUpdateTeacher({ ...teacher, ...newTeacherData });
                        setEditingTeacherId(null);
                        setNewTeacherData({
                          name: '',
                          username: '',
                          password: '',
                          isMaster: false,
                        });
                      }}
                      className="min-w-[60px] text-sm whitespace-nowrap bg-yellow-300 text-yellow-800 px-2 py-1.5 rounded hover:bg-yellow-400"
                      aria-label="教師情報保存ボタン"
                    >
                      保存
                    </button>
                    <button
                      onClick={() => {
                        setEditingTeacherId(null);
                        setNewTeacherData({
                          name: '',
                          username: '',
                          password: '',
                          isMaster: false,
                        });
                      }}
                      className="min-w-[60px] text-sm whitespace-nowrap bg-purple-100 text-purple-700 px-2 py-1.5 rounded hover:bg-purple-200"
                      aria-label="教師情報編集キャンセルボタン"
                    >
                      キャンセル
                    </button>
                    <button
                      onClick={() => {
                        setConfirmDialog({
                          title: '教師の削除',
                          message: `${teacher.name}を削除してもよろしいですか？`,
                          onConfirm: () => {
                            onDeleteTeacher(teacher.id);
                            setEditingTeacherId(null);
                            setNewTeacherData({
                              name: '',
                              username: '',
                              password: '',
                              isMaster: false,
                            });
                            setConfirmDialog(null);
                          },
                          onCancel: () => setConfirmDialog(null),
                        });
                      }}
                      className="min-w-[60px] text-sm whitespace-nowrap bg-pink-300 text-pink-800 px-2 py-1.5 rounded hover:bg-pink-400"
                      aria-label="教師削除ボタン"
                    >
                      削除
                    </button>
                  </div>
                </div>
              ) : (
                <>
                  <div
                    className="flex-1 cursor-pointer"
                    onClick={() => onSelectTeacher(teacher)}
                    aria-label={`教師選択: ${teacher.name}`}
                  >
                    <div className="font-medium text-purple-700 hover:text-purple-800">
                      {teacher.name} {teacher.isMaster && '(マスター)'}
                    </div>
                    <div className="text-sm text-purple-600">
                      ユーザー名: {teacher.username}
                    </div>
                  </div>
                  <div className="flex items-center space-x-2">
                    {/* 編集ボタン */}
                    {(currentUser.isMaster || currentUser.id === teacher.id) && (
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          setEditingTeacherId(teacher.id);
                          setNewTeacherData({
                            name: teacher.name,
                            username: teacher.username,
                            password: teacher.password,
                            isMaster: teacher.isMaster,
                          });
                        }}
                        className="text-purple-500 hover:text-purple-700 bg-transparent hover:bg-purple-200 p-2 rounded"
                        aria-label={`編集 ${teacher.name}`}
                      >
                        <Edit3 size={20} />
                      </button>
                    )}
                  </div>
                </>
              )}
            </div>
          );
        })}
      </div>

      {/* モバイル表示の場合の閉じるボタン */}
      {onClose && (
        <button
          onClick={onClose}
          className="mt-4 w-full py-2 bg-purple-100 text-purple-700 rounded hover:bg-purple-200"
          aria-label="サイドバーを閉じる"
        >
          閉じる
        </button>
      )}

      {/* 確認ダイアログのレンダリング */}
      {confirmDialog && (
        <ConfirmDialog
          title={confirmDialog.title}
          message={confirmDialog.message}
          onConfirm={confirmDialog.onConfirm}
          onCancel={confirmDialog.onCancel}
        />
      )}
    </div>
  );
};

TeacherList.propTypes = {
  teachers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      username: PropTypes.string.isRequired,
      password: PropTypes.string.isRequired,
      isMaster: PropTypes.bool.isRequired,
    })
  ).isRequired,
  selectedTeacher: PropTypes.object,
  onSelectTeacher: PropTypes.func.isRequired,
  onAddTeacher: PropTypes.func.isRequired,
  onUpdateTeacher: PropTypes.func.isRequired,
  onDeleteTeacher: PropTypes.func.isRequired,
  onClose: PropTypes.func, // サイドバー閉じる機能用
  currentUser: PropTypes.shape({ // 現在のユーザー情報
    id: PropTypes.string.isRequired,
    isMaster: PropTypes.bool.isRequired,
    // 他のプロパティも必要に応じて追加
  }).isRequired,
  onOpenMessaging: PropTypes.func.isRequired, // このプロパティは不要になるかもしれません
};

// 科目タブコンポーネント
const SubjectTabs = ({ subjects, selectedSubject, onSelectSubject }) => (
  <div className="flex flex-wrap gap-2 mb-6">
    {subjects.map(subject => (
      <button
        key={subject.id}
        onClick={() => onSelectSubject(subject)}
        className={`px-4 py-2 rounded-full font-medium transition-colors duration-200 ${
          selectedSubject.id === subject.id
            ? 'bg-purple-300 text-purple-800 shadow'
            : 'bg-purple-100 text-purple-700 hover:bg-purple-200'
        }`}
        aria-label={`科目タブ: ${subject.name}`}
      >
        {subject.name}
      </button>
    ))}
  </div>
);

SubjectTabs.propTypes = {
  subjects: PropTypes.array.isRequired,
  selectedSubject: PropTypes.object.isRequired,
  onSelectSubject: PropTypes.func.isRequired,
};

// TaskItemコンポーネント
const TaskItem = ({
  item,
  onCheck,
  onTeacherCheck,
  isTeacher,
  onPassFail,
  onUpdateItem,
  onDeleteItem,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedItem, setEditedItem] = useState({ ...item });
  const [confirmDialog, setConfirmDialog] = useState(null);

  const linkifyOptions = {
    target: '_blank',
    rel: 'noopener noreferrer',
    className: 'text-purple-500 underline',
  };

  // 先生確認用のボタンコンポーネント
  const TeacherCheckButton = () => {
    const handleCheck = (status) => {
      if (item.teacherCheck === status) {
        onTeacherCheck(null);
      } else {
        onTeacherCheck(status);
      }
    };

    return (
      <div className="flex flex-col items-center">
        <span className="font-medium text-purple-700 text-base">先生確認</span>
        <div className="flex gap-2 mt-1">
          <button
            onClick={() => handleCheck('maru')}
            className={`w-8 h-8 rounded-full flex items-center justify-center font-bold text-2xl ${
              item.teacherCheck === 'maru'
                ? 'bg-yellow-300 text-yellow-800'
                : 'bg-purple-200 hover:bg-purple-300'
            }`}
            aria-label={`丸チェック ${item.text}`}
          >
            ○
          </button>
          <button
            onClick={() => handleCheck('triangle')}
            className={`w-8 h-8 rounded-full flex items-center justify-center font-bold text-2xl ${
              item.teacherCheck === 'triangle'
                ? 'bg-purple-300 text-purple-800'
                : 'bg-purple-200 hover:bg-purple-300'
            }`}
            aria-label={`△チェック ${item.text}`}
          >
            △
          </button>
          <button
            onClick={() => handleCheck('batsu')}
            className={`w-8 h-8 rounded-full flex items-center justify-center font-bold text-2xl ${
              item.teacherCheck === 'batsu'
                ? 'bg-pink-300 text-pink-800'
                : 'bg-purple-200 hover:bg-purple-300'
            }`}
            aria-label={`バツチェック ${item.text}`}
          >
            ×
          </button>
        </div>
      </div>
    );
  };

  // 先生確認の表示コンポーネント
  const TeacherCheckDisplay = () => {
    const checkMap = {
      maru: '○',
      triangle: '△',
      batsu: '×',
    };

    return (
      <div className="flex flex-col items-center">
        <span className="font-medium text-purple-700 text-base">先生確認</span>
        <span
          className={`${
            item.teacherCheck
              ? `mt-1 font-bold text-3xl ${
                  item.teacherCheck === 'maru'
                    ? 'text-yellow-600'
                    : item.teacherCheck === 'triangle'
                    ? 'text-purple-600'
                    : 'text-pink-600'
                }`
              : `mt-2.5 text-sm font-bold italic text-purple-600`
          }`}
        >
          {item.teacherCheck ? checkMap[item.teacherCheck] : '未確認'}
        </span>
      </div>
    );
  };

  // 合否判定のボタン（教師のみ）
  const PassFailButton = () => {
    const handlePassClick = () => {
      onPassFail('pass');
    };

    const handleFailClick = () => {
      onPassFail('fail');
    };

    return (
      <div className="flex gap-2 mt-1">
        <button
          onClick={handlePassClick}
          className={`px-4 py-2 rounded text-sm font-bold ${
            item.passStatus === 'pass'
              ? 'bg-yellow-300 text-yellow-800'
              : 'bg-purple-200 text-purple-600 hover:bg-purple-300'
          }`}
          aria-label={`合格 ${item.text}`}
        >
          合格
        </button>
        <button
          onClick={handleFailClick}
          className={`px-4 py-2 rounded text-sm font-bold ${
            item.passStatus === 'fail'
              ? 'bg-pink-300 text-pink-800'
              : 'bg-purple-200 text-purple-600 hover:bg-purple-300'
          }`}
          aria-label={`不合格 ${item.text}`}
        >
          不合格
        </button>
      </div>
    );
  };

  // 合否判定の表示（生徒用）
  const PassFailDisplay = () => {
    if (!item.passStatus)
      return <span className="text-purple-600 text-sm mt-1">未判定</span>;

    return (
      <span
        className={`font-bold text-lg mt-1 ${
          item.passStatus === 'pass' ? 'text-yellow-600' : 'text-pink-600'
        }`}
      >
        {item.passStatus === 'pass' ? '合格' : '不合格'}
      </span>
    );
  };

  // 編集フォームのハンドル
  const handleSave = () => {
    // バリデーション
    if (!editedItem.text || !editedItem.text.trim()) {
      alert('チェック項目のテキストを入力してください。');
      return;
    }

    // 更新ハンドラーを呼び出す
    onUpdateItem({ ...editedItem, id: item.id });

    // 編集モードを終了
    setIsEditing(false);
  };

  const handleCancel = () => {
    // 編集内容をリセット
    setEditedItem({ ...item });
    setIsEditing(false);
  };

  // 削除ボタンのハンドル
  const handleDelete = () => {
    setConfirmDialog({
      title: 'チェック項目の削除',
      message: 'このチェック項目を削除してもよろしいですか？',
      onConfirm: () => {
        onDeleteItem(item.id);
        setConfirmDialog(null);
      },
      onCancel: () => setConfirmDialog(null),
    });
  };

  return (
    <div className="mb-4 p-4 bg-white rounded-lg shadow">
      {isEditing ? (
        // 編集フォームの表示
        <div className="flex flex-col gap-4">
          {/* チェック項目の編集 */}
          <div className="flex flex-col gap-1">
            <label
              htmlFor={`edit-text-${item.id}`}
              className="font-medium text-purple-700"
            >
              チェック項目
              <span className="text-purple-700 ml-1">*</span>
            </label>
            <textarea
              id={`edit-text-${item.id}`}
              value={editedItem.text || ''}
              onChange={(e) => setEditedItem({ ...editedItem, text: e.target.value })}
              placeholder="例: 数学の問題を解く"
              className="p-2 border rounded focus:outline-none focus:ring-2 focus:ring-purple-300"
              rows={2}
              required
            />
          </div>

          {/* 宿題期限、確認テスト日時、スコアの編集を同じ行に並べる */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            {/* 宿題期限の編集 */}
            <div className="flex flex-col gap-1">
              <label
                htmlFor={`edit-dueDate-${item.id}`}
                className="font-medium text-purple-700"
              >
                宿題期限
              </label>
              <input
                id={`edit-dueDate-${item.id}`}
                type="date"
                value={editedItem.dueDate || ''}
                onChange={(e) => setEditedItem({ ...editedItem, dueDate: e.target.value })}
                className="p-2 border rounded focus:outline-none focus:ring-2 focus:ring-purple-300"
                placeholder="例: 2024-12-31"
              />
              {!editedItem.dueDate && (
                <span className="text-purple-600 text-sm">
                  宿題期限が未設定です。
                </span>
              )}
            </div>

            {/* 確認テスト日時の編集 */}
            <div className="flex flex-col gap-1">
              <label
                htmlFor={`edit-testDate-${item.id}`}
                className="font-medium text-purple-700"
              >
                確認テスト日時
              </label>
              <input
                id={`edit-testDate-${item.id}`}
                type="date"
                value={editedItem.testDate || ''}
                onChange={(e) => setEditedItem({ ...editedItem, testDate: e.target.value })}
                className="p-2 border rounded focus:outline-none focus:ring-2 focus:ring-purple-300"
                placeholder="例: 2024-12-15"
              />
              {!editedItem.testDate && (
                <span className="text-purple-600 text-sm">
                  確認テスト日時が未設定です。
                </span>
              )}
            </div>

            {/* スコアの編集 */}
            <div className="flex flex-col gap-1">
              <label
                htmlFor={`edit-testScore-${item.id}`}
                className="font-medium text-purple-700"
              >
                スコア
                <span className="text-purple-700 ml-1">(0〜100)</span>
              </label>
              <input
                id={`edit-testScore-${item.id}`}
                type="number"
                value={editedItem.testScore || ''}
                onChange={(e) => {
                  const value = e.target.value;
                  if (
                    value === '' ||
                    (Number(value) >= 0 && Number(value) <= 100)
                  ) {
                    setEditedItem({ ...editedItem, testScore: value });
                  }
                }}
                placeholder="例: 85"
                className={`w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-purple-300 ${
                  (editedItem.testScore < 0 || editedItem.testScore > 100) &&
                  'border-pink-500'
                }`}
                min="0"
                max="100"
              />
              {(editedItem.testScore < 0 || editedItem.testScore > 100) && (
                <span className="text-pink-600 text-sm">
                  スコアは0から100の範囲で入力してください。
                </span>
              )}
            </div>
          </div>

          {/* 保存、削除、キャンセルボタンを同じ行に配置 */}
          <div className="flex justify-end mt-4 space-x-2">
            <button
              onClick={handleSave}
              className="bg-purple-300 text-purple-800 px-5 py-2 rounded hover:bg-purple-400"
              aria-label="チェック項目保存ボタン"
            >
              保存
            </button>
            <button
              onClick={handleDelete}
              className="bg-pink-300 text-pink-800 px-5 py-2 rounded hover:bg-pink-400"
              aria-label="チェック項目削除ボタン"
            >
              削除
            </button>
            <button
              onClick={handleCancel}
              className="bg-purple-100 text-purple-700 px-5 py-2 rounded hover:bg-purple-200"
              aria-label="チェック項目編集キャンセルボタン"
            >
              キャンセル
            </button>
          </div>
        </div>
      ) : (
        // 通常表示
        <div className="flex flex-col gap-3">
          {/* チェック項目 */}
          <div className="flex items-center">
            {/* チェックボックス */}
            <button
              className="text-purple-500 mr-2"
              onClick={onCheck}
              aria-label={item.checked ? 'チェックを外す' : 'チェックする'}
            >
              {item.checked ? (
                <CheckSquare size={20} />
              ) : (
                <Square size={20} />
              )}
            </button>
            <span
              className={`block font-bold ${
                item.checked ? 'line-through text-purple-400' : 'text-purple-800'
              } whitespace-pre-wrap break-words overflow-hidden`}
              style={{ wordBreak: 'break-word' }}
            >
              {item.text && item.text.trim() !== '' ? (
                <Linkify
                  options={{
                    ...linkifyOptions,
                    className: 'text-purple-500 underline break-words',
                  }}
                >
                  {item.text}
                </Linkify>
              ) : (
                <span className="text-purple-500 italic">未設定</span>
              )}
            </span>
          </div>

          {/* 宿題期限と先生確認 */}
          <div className="flex flex-wrap items-start mt-2">
            <div className="flex flex-col mr-4">
              <span className="font-medium text-purple-700">宿題期限</span>
              <span
                className="p-2 bg-transparent text-purple-700 text-center text-base font-bold min-h-[24px]"
                aria-label={`宿題期限: ${item.dueDate || '未設定'}`}
              >
                {item.dueDate || (
                  <span className="text-sm italic">未設定</span>
                )}
              </span>
            </div>

            <div className="flex flex-col">
              {isTeacher ? <TeacherCheckButton /> : <TeacherCheckDisplay />}
            </div>
          </div>

          {/* 確認テスト日時、スコア */}
          <div className="flex flex-wrap items-start mt-2">
            {/* 確認テスト日時 */}
            <div className="flex flex-col mr-4">
              <span className="font-medium text-purple-700">
                確認テスト日時
              </span>
              <span
                className="p-2 bg-transparent text-purple-700 text-center text-base font-bold min-h-[24px]"
                aria-label={`確認テスト日時: ${item.testDate || '未設定'}`}
              >
                {item.testDate || (
                  <span className="text-sm italic">未設定</span>
                )}
              </span>
            </div>

            {/* スコア */}
            <div className="flex flex-col">
              <span className="font-medium text-purple-700">スコア</span>
              <span
                className={`p-2 bg-transparent text-purple-700 text-center text-base font-bold min-h-[24px]`}
                aria-label={`スコア: ${item.testScore || '未設定'}`}
              >
                {item.testScore || (
                  <span className="text-sm italic">未設定</span>
                )}
              </span>
            </div>
          </div>

          {/* 最終確認 */}
          <div className="flex flex-wrap items-center mt-2">
            <div className="flex flex-col">
              <span className="font-medium text-purple-700 text-base">
                最終確認
              </span>
              {isTeacher ? <PassFailButton /> : <PassFailDisplay />}
            </div>
          </div>

          {/* 編集ボタン（教師のみ） */}
          {isTeacher && (
            <div className="flex justify-end mt-2">
              <button
                onClick={() => setIsEditing(true)}
                className="text-purple-500 hover:text-purple-700"
                aria-label="項目を編集"
              >
                <Edit2 size={20} />
              </button>
            </div>
          )}
        </div>
      )}

      {/* 確認ダイアログの追加 */}
      {confirmDialog && (
        <ConfirmDialog
          title={confirmDialog.title}
          message={confirmDialog.message}
          onConfirm={confirmDialog.onConfirm}
          onCancel={confirmDialog.onCancel}
        />
      )}
    </div>
  );
};

TaskItem.propTypes = {
  item: PropTypes.object.isRequired,
  onCheck: PropTypes.func.isRequired,
  onTeacherCheck: PropTypes.func.isRequired,
  isTeacher: PropTypes.bool.isRequired,
  onPassFail: PropTypes.func.isRequired,
  onUpdateItem: PropTypes.func.isRequired,
  onDeleteItem: PropTypes.func.isRequired,
};

// 課題編集フォームコンポーネント
const EditTaskForm = ({ task, onSave, onCancel }) => {
  const [title, setTitle] = useState(task.title || '');
  const [items, setItems] = useState(
    Object.values(task.items || {}).map(item => ({
      ...item,
      id: item.id || uuidv4(), // id がない場合は新規生成
    })).sort((a, b) => (a.order || 0) - (b.order || 0))
  );

  const handleAddItem = () => {
    setItems(prev => [
      ...prev,
      {
        id: uuidv4(),
        text: '',
        dueDate: '',
        testDate: '',
        testScore: '',
        checked: false,
        order: prev.length // 現在の配列長を基にorderを設定
      }
    ]);
  };

  const handleRemoveItem = index => {
    setItems(prev => {
      const newItems = prev.filter((_, i) => i !== index);
      // 削除後にorderを再設定
      return newItems.map((item, idx) => ({ ...item, order: idx }));
    });
  };

  const handleSubmit = () => {
    const validItems = items.filter(item => item.text.trim());
    if (!title.trim() || validItems.length === 0) {
      alert('タイトルと少なくとも一つの項目を入力してください。');
      return;
    }
    const updatedTask = {
      ...task,
      title,
      items: validItems.reduce((acc, item, index) => {
        acc[item.id] = {
          ...item,
          order: index // 現在の配列インデックスに基づいてorderを設定
        };
        return acc;
      }, {})
    };
    onSave(updatedTask);
  };

  return (
    <div className="p-4 mb-6 bg-yellow-50 rounded-lg shadow">
      {/* タイトルセクション */}
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-1 mb-4">
          <label htmlFor="task-title" className="font-medium text-purple-700">
            課題タイトル
            <span className="text-red-500 ml-1">*</span>
          </label>
          <input
            id="task-title" // 一意のIDを追加
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
            className="w-full p-2 border border-purple-300 rounded mb-1 focus:outline-none focus:ring-2 focus:ring-purple-300"
            placeholder="例: 数学の宿題"
            aria-label="課題タイトル入力"
          />
        </div>

        {/* チェック項目セクション */}
        <div className="flex flex-col gap-4">
          {items.map((item, index) => (
            <div key={item.id} className="flex flex-col gap-3">
              {/* 各項目の編集フィールド */}
              <div className="flex flex-col gap-1">
                <label
                  htmlFor={`add-text-${item.id}`}
                  className="sr-only"
                >
                  チェック項目 {index + 1}
                </label>
                <textarea
                  id={`add-text-${item.id}`} // 一意のIDを追加
                  value={item.text}
                  onChange={(e) => {
                    const newItems = [...items];
                    newItems[index].text = e.target.value;
                    setItems(newItems);
                  }}
                  placeholder="例: 数学の問題を解く"
                  className="p-2 border border-purple-300 rounded mb-1 focus:outline-none focus:ring-2 focus:ring-purple-300"
                  rows={2}
                  required
                  aria-label={`チェック項目 ${index + 1} 入力`}
                />
              </div>
              
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                {/* 宿題期限フィールド */}
                <div className="flex flex-col gap-1">
                  <label
                    htmlFor={`dueDate-${task.id}-${item.id}`}
                    className="font-medium text-purple-700"
                  >
                    宿題期限
                    <span className="text-purple-500 ml-1">(任意)</span>
                  </label>
                  <input
                    id={`dueDate-${task.id}-${item.id}`}
                    type="date"
                    value={item.dueDate}
                    onChange={(e) => {
                      const newItems = [...items];
                      newItems[index].dueDate = e.target.value;
                      setItems(newItems);
                    }}
                    className="p-2 border border-purple-300 rounded mb-1 focus:outline-none focus:ring-2 focus:ring-purple-300"
                    placeholder="例: 2024-12-31"
                  />
                  {!item.dueDate && (
                    <span className="text-purple-500 text-sm">
                      宿題期限が未設定です。
                    </span>
                  )}
                </div>

                {/* 確認テスト日時フィールド */}
                <div className="flex flex-col gap-1">
                  <label
                    htmlFor={`testDate-${task.id}-${item.id}`}
                    className="font-medium text-purple-700"
                  >
                    確認テスト日時
                    <span className="text-purple-500 ml-1">(任意)</span>
                  </label>
                  <input
                    id={`testDate-${task.id}-${item.id}`}
                    type="date"
                    value={item.testDate}
                    onChange={(e) => {
                      const newItems = [...items];
                      newItems[index].testDate = e.target.value;
                      setItems(newItems);
                    }}
                    className="p-2 border border-purple-300 rounded mb-1 focus:outline-none focus:ring-2 focus:ring-purple-300"
                    placeholder="例: 2024-12-15"
                  />
                  {!item.testDate && (
                    <span className="text-purple-500 text-sm">
                      確認テスト日時が未設定です。
                    </span>
                  )}
                </div>

                {/* スコアフィールド */}
                <div className="flex flex-col gap-1">
                  <label
                    htmlFor={`testScore-${task.id}-${item.id}`}
                    className="font-medium text-purple-700"
                  >
                    スコア
                    <span className="text-purple-500 ml-1">(0〜100)</span>
                  </label>
                  <input
                    id={`testScore-${task.id}-${item.id}`}
                    type="number"
                    value={item.testScore}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value === '' || (Number(value) >= 0 && Number(value) <= 100)) {
                        const newItems = [...items];
                        newItems[index].testScore = value;
                        setItems(newItems);
                      }
                    }}
                    placeholder="例: 85"
                    className={`w-full p-2 border rounded mb-1 focus:outline-none focus:ring-2 focus:ring-purple-300 ${
                      (item.testScore < 0 || item.testScore > 100) && 'border-red-500'
                    }`}
                    min="0"
                    max="100"
                    aria-label="スコア入力"
                  />
                  {(item.testScore < 0 || item.testScore > 100) && (
                    <span className="text-red-500 text-sm">
                      スコアは0から100の範囲で入力してください。
                    </span>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
        
        {/* 項目の追加ボタン */}
        <div className="flex justify-start mt-4">
          <button
            type="button"
            onClick={handleAddItem}
            className="text-purple-500 hover:text-purple-600 flex items-center gap-1"
            aria-label="項目を追加ボタン"
          >
            <PlusCircle size={20} />
            項目を追加
          </button>
        </div>
        
        {/* 保存とキャンセルボタン */}
        <div className="flex justify-between mt-6">
          <button
            type="button"
            onClick={handleSubmit}
            className="bg-purple-300 text-purple-800 px-5 py-2 rounded hover:bg-purple-400"
            aria-label="課題保存ボタン"
          >
            保存
          </button>
          <button
            type="button"
            onClick={onCancel}
            className="bg-purple-100 text-purple-700 px-5 py-2 rounded hover:bg-purple-200"
            aria-label="課題編集キャンセルボタン"
          >
            キャンセル
          </button>
        </div>
      </div>
    </div>
  );
};

EditTaskForm.propTypes = {
  task: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

// タスクカードコンポーネント
const TaskCard = ({
  task,
  isTeacher,
  onDeleteTask,
  onUpdateTask,
  onCheckItem,
  onTeacherCheckItem,
  onPassFailItem,
  onUpdateItem,
  onDeleteItem,
  getStudentId, // 親から渡される関数
  selectedSubject, // 親から渡されるオブジェクト
  currentUser, // 追加: currentUser をプロパティとして受け取る
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState(FILTERS[0]);

  return (
    <div className="p-4 mb-6 bg-yellow-50 rounded-lg shadow">
      {isEditing ? (
        <EditTaskForm
          task={task}
          onSave={(updatedTask) => {
            onUpdateTask(updatedTask);
            setIsEditing(false);
          }}
          onCancel={() => setIsEditing(false)}
        />
      ) : (
        <>
          {/* 課題のタイトルとドラッグハンドル */}
          <div className="flex justify-between items-center mb-4">
            <div className="flex items-center">
              {isTeacher && (
                <span
                  {...task.dragHandleProps}
                  className="mr-2 cursor-grab text-purple-500 hover:text-purple-700"
                  aria-label="課題をドラッグ"
                >
                  <Menu size={22} />
                </span>
              )}
              <div className="font-semibold text-lg text-purple-700">
                {task.title}
              </div>
            </div>
            {isTeacher && (
              <div className="flex items-center gap-2">
                <button
                  onClick={() => setIsEditing(true)}
                  className="text-purple-500 hover:text-purple-700"
                  aria-label="課題を編集"
                >
                  <Edit2 size={22} />
                </button>

                <button
                  onClick={() => {
                    setConfirmDialog({
                      title: '課題の削除',
                      message: 'この課題を削除してもよろしいですか？',
                      onConfirm: () => {
                        onDeleteTask();
                        setConfirmDialog(null);
                      },
                      onCancel: () => setConfirmDialog(null),
                    });
                  }}
                  className="text-pink-500 hover:text-pink-700"
                  aria-label="課題を削除"
                >
                  <X size={22} />
                </button>
              </div>
            )}
          </div>

          {/* フィルターの選択UI */}
          <div className="mb-4">
            <select
              value={selectedFilter.id}
              onChange={(e) => {
                const filter = FILTERS.find((f) => f.id === e.target.value);
                setSelectedFilter(filter);
              }}
              className="w-full p-2 border border-purple-300 rounded focus:outline-none focus:ring-2 focus:ring-purple-300"
              aria-label="課題フィルター選択"
            >
              {FILTERS.map((filter) => (
                <option key={filter.id} value={filter.id}>
                  {filter.name}
                </option>
              ))}
            </select>
          </div>

          {/* 折りたたみボタン */}
          <button
            onClick={() => setIsCollapsed(!isCollapsed)}
            className="mb-4 text-purple-500 hover:text-purple-600 flex items-center gap-1 focus:outline-none transition-colors duration-200"
            aria-label={
              isCollapsed ? 'チェック項目を展開' : 'チェック項目を折りたたむ'
            }
            aria-expanded={!isCollapsed}
            aria-controls={`task-items-${task.id}`}
          >
            {isCollapsed ? 'チェック項目を表示' : 'チェック項目を非表示'}
          </button>

          {/* チェック項目の表示 */}
         {!isCollapsed && (
            <Droppable droppableId={`items-${task.id}`} type={`taskItems-${task.id}`}>
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className="space-y-4"
                >
                  {Object.entries(task.items || {})
                    .sort(([, a], [, b]) => (a.order || 0) - (b.order || 0))
                    .map(([itemId, item], index) => (
                      <Draggable
                        key={itemId}
                        draggableId={`${task.id}-${itemId}`}
                        index={index}
                        isDragDisabled={!currentUser.isMaster}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              ...provided.draggableProps.style,
                              opacity: snapshot.isDragging ? 0.8 : 1
                            }}
                          >
                            <TaskItem
                              item={{ ...item, id: itemId }}
                              isTeacher={isTeacher}
                              onCheck={() => onCheckItem(task.id, itemId)}
                              onTeacherCheck={(status) =>
                                onTeacherCheckItem(task.id, itemId, status)
                              }
                              onPassFail={(status) =>
                                onPassFailItem(task.id, itemId, status)
                              }
                              onUpdateItem={(updatedItem) =>
                                onUpdateItem(task.id, itemId, updatedItem)
                              }
                              onDeleteItem={() => onDeleteItem(task.id, itemId)}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          )}

          {/* 確認ダイアログ */}
          {confirmDialog && (
            <ConfirmDialog
              title={confirmDialog.title}
              message={confirmDialog.message}
              onConfirm={confirmDialog.onConfirm}
              onCancel={confirmDialog.onCancel}
            />
          )}
        </>
      )}
    </div>
  );
};

TaskCard.propTypes = {
  task: PropTypes.object.isRequired,
  isTeacher: PropTypes.bool.isRequired,
  onDeleteTask: PropTypes.func.isRequired,
  onUpdateTask: PropTypes.func.isRequired,
  onCheckItem: PropTypes.func.isRequired,
  onTeacherCheckItem: PropTypes.func.isRequired,
  onPassFailItem: PropTypes.func.isRequired,
  onUpdateItem: PropTypes.func.isRequired,
  onDeleteItem: PropTypes.func.isRequired,
  getStudentId: PropTypes.func.isRequired, // 親から渡される関数
  selectedSubject: PropTypes.object.isRequired, // 親から渡されるオブジェクト
  currentUser: PropTypes.object.isRequired, // 追加: currentUser をプロパティとして受け取る
};

// タスク追加フォームコンポーネント
const AddTaskForm = ({ onAddTask, onCancel }) => {
  const [title, setTitle] = useState('');
  const [items, setItems] = useState([
    { id: uuidv4(), text: '', dueDate: '', testDate: '', testScore: '' }
  ]);

  const handleAddItem = () => {
    setItems([...items, { id: uuidv4(), text: '', dueDate: '', testDate: '', testScore: '' }]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // バリデーション
    if (!title.trim() || items.some(item => !item.text.trim())) {
      alert('タイトルとすべてのチェック項目を入力してください。');
      return;
    }
    // チェック項目をオブジェクト形式に変換
    const itemsObject = items.reduce((acc, item, index) => {
      acc[item.id] = { ...item, order: index };
      return acc;
    }, {});
    onAddTask({ title, items: itemsObject });
  };

  return (
    <form onSubmit={handleSubmit} className="p-4 bg-yellow-50 rounded-lg shadow">
      <div className="flex flex-col gap-4">
        <div>
          <label htmlFor="task-title" className="font-medium text-purple-700">課題タイトル</label>
          <input
            id="task-title" // 一意のIDを追加
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
            className="w-full p-2 border border-purple-300 rounded mb-1 focus:outline-none focus:ring-2 focus:ring-purple-300"
            placeholder="例: 週末の数学課題"
            aria-label="課題タイトル入力"
          />
        </div>

        <div>
          <label className="font-medium text-purple-700">チェック項目</label>
          {items.map((item, index) => (
            <div key={item.id} className="flex flex-col gap-1 mb-3">
              <label htmlFor={`add-text-${item.id}`} className="sr-only">チェック項目 {index + 1}</label>
              <textarea
                id={`add-text-${item.id}`} // 一意のIDを追加
                value={item.text}
                onChange={(e) => {
                  const newItems = [...items];
                  newItems[index].text = e.target.value;
                  setItems(newItems);
                }}
                placeholder="例: 数学の問題を解く"
                className="p-2 border border-purple-300 rounded mb-1 focus:outline-none focus:ring-2 focus:ring-purple-300"
                rows={2}
                required
                aria-label={`チェック項目 ${index + 1} 入力`}
              />
            </div>
          ))}
        </div>

        {/* 項目の追加ボタン */}
        <div className="flex justify-start mt-4">
          <button
            type="button"
            onClick={handleAddItem}
            className="text-purple-500 hover:text-purple-600 flex items-center gap-1"
            aria-label="項目を追加ボタン"
          >
            <PlusCircle size={20} />
            項目を追加
          </button>
        </div>

        {/* 保存とキャンセルボタン */}
        <div className="flex justify-between mt-6">
          <button
            type="submit"
            className="bg-purple-300 text-purple-800 px-5 py-2 rounded hover:bg-purple-400"
            aria-label="課題保存ボタン"
          >
            保存
          </button>
          <button
            type="button"
            onClick={onCancel}
            className="bg-purple-100 text-purple-700 px-5 py-2 rounded hover:bg-purple-200"
            aria-label="課題編集キャンセルボタン"
          >
            キャンセル
          </button>
        </div>
      </div>
    </form>
  );
};

AddTaskForm.propTypes = {
  onAddTask: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

// ナビゲーションバーコンポーネント
const Navbar = ({
  currentUser,
  selectedStudent,
  selectedTeacher,
  onLogout,
  onToggleSidebar,
  onOpenMessaging,
  onOpenNotes,
  teachers,
  onExport,
}) => {

  const [isLogoutConfirmOpen, setIsLogoutConfirmOpen] = useState(false);
  const { getUnreadCount } = useUnreadMessages(currentUser);

  // 学年コードから学年名を取得する関数
  const getGradeName = (gradeId) => {
    const grade = GRADES.find((g) => g.id === gradeId);
    return grade ? grade.name : gradeId;
  };

  // マスター教師を見つける
  const masterTeacher = useMemo(() => {
    return teachers?.find((teacher) => teacher.isMaster);
  }, [teachers]);

  // 会話IDの生成
  const conversationId = useMemo(() => {
    if (currentUser.role === 'student' && masterTeacher) {
      return currentUser.id < masterTeacher.id
        ? `${currentUser.id}_${masterTeacher.id}`
        : `${masterTeacher.id}_${currentUser.id}`;
    }
    return null;
  }, [currentUser, masterTeacher]);

  // 未読メッセージ数の取得
  const unreadCount = conversationId ? getUnreadCount(conversationId) : 0;

 // カスタムフックを使用して未読メモ数を取得
 const { unreadNotesCount } = useUnreadNotesCount(currentUser, selectedStudent);

  return (
    <nav className="bg-yellow-50 border-b mb-6 md:mb-8">
      <div className="max-w-screen-xl mx-auto p-4 flex flex-col">
        {/* 第一行: サイドバー開閉ボタンとタイトル */}
        <div className="flex items-center mb-2">
          {/* サイドバーを開くボタン（教師のみ表示） */}
          {currentUser.role === 'teacher' && (
            <button
              onClick={onToggleSidebar}
              className="text-purple-500 focus:outline-none mr-4"
              aria-label="サイドバーを開く"
            >
              <Menu size={24} />
            </button>
          )}
          {/* タイトル */}
          <h1 className="text-xl md:text-2xl font-bold text-purple-700 flex-1">
            S-Plus 課題管理
          </h1>
        </div>

        {/* 第二行: 編集中の情報とユーザー情報・ボタンを右寄せ */}
        <div className="flex justify-end items-center space-x-6">
          {/* 編集中の生徒が選択されている場合 */}
          {selectedStudent && (
            <span className="text-purple-700 text-sm md:text-base">
              編集中: {selectedStudent.name}
            </span>
          )}

          {/* ユーザー名と役職の表示 */}
          <span className="text-purple-700 text-sm md:text-base truncate max-w-xs">
            {currentUser.name}{' '}
            {currentUser.role === 'teacher'
              ? currentUser.isMaster
                ? '（マスター）'
                : '（教師）'
              : `（${getGradeName(currentUser.grade)}）`}
          </span>

          {/* 生徒の場合のみメッセージボタンを表示 */}
          {currentUser.role === 'student' && (
            <button
              onClick={() => onOpenMessaging(masterTeacher)} // 生徒の場合、マスター教師とのメッセージを開く
              className="relative text-purple-500 hover:text-yellow-500"
              aria-label="メッセージ"
            >
              <MessageSquare size={24} />
              {unreadCount > 0 && (
                <span className="absolute -top-2 -right-2 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-yellow-100 bg-yellow-600 rounded-full">
                  {unreadCount}
                </span>
              )}
            </button>
          )}

          {/* 教師のみメモボタンを表示 */}
          {currentUser.role === 'teacher' && selectedStudent && (
            <button
              onClick={onOpenNotes}
              className="relative text-purple-500 hover:text-yellow-500" // relative ポジションを追加
              aria-label="メモ"
            >
              <FileText size={24} />
              {unreadNotesCount > 0 && (
                <span className="absolute -top-2 -right-2 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-yellow-100 bg-yellow-600 rounded-full">
                  {unreadNotesCount}
                </span>
              )}
            </button>
          )}

          {/* エクスポートボタンを追加 */}
          {currentUser.role === 'teacher' && currentUser.isMaster && selectedStudent && (
            <button
              onClick={onExport}
              className="text-purple-500 hover:text-yellow-600 focus:outline-none"
              aria-label="課題をエクスポート"
            >
              {/* アイコンを使用する場合は適切なアイコンをインポートしてください */}
              <FileText size={24} />
            </button>
          )}

          {/* ログアウトボタン */}
          <button
            onClick={() => setIsLogoutConfirmOpen(true)} // 修正
            className="text-purple-500 hover:text-pink-500 focus:outline-none"
            aria-label="ログアウト"
          >
            <LogOut size={24} />
          </button>
        </div>
      </div>

      {/* ConfirmDialog のレンダリング */}
      {isLogoutConfirmOpen && (
        <ConfirmDialog
          title="ログアウトの確認"
          message="本当にログアウトしますか？"
          onConfirm={() => {
            onLogout(); // 確認後にログアウト処理を実行
            setIsLogoutConfirmOpen(false); // ダイアログを閉じる
          }}
          onCancel={() => setIsLogoutConfirmOpen(false)} // キャンセル時にダイアログを閉じる
          confirmButtonText="ログアウト" // 確認ボタンのラベルを「ログアウト」に変更
          cancelButtonText="キャンセル" // キャンセルボタンのラベル
        />
      )}
    </nav>
  );
};

Navbar.propTypes = {
  currentUser: PropTypes.object.isRequired,
  selectedStudent: PropTypes.object,
  selectedTeacher: PropTypes.object,
  onLogout: PropTypes.func.isRequired,
  onToggleSidebar: PropTypes.func.isRequired,
  onOpenMessaging: PropTypes.func.isRequired,
  onOpenNotes: PropTypes.func.isRequired,
  teachers: PropTypes.array.isRequired,
  onExport: PropTypes.func.isRequired, // エクスポートハンドラーの型定義を追加
};

// メインコンポーネント
const SubjectTaskBoard = () => { // コンポーネント名を SubjectTaskBoard に戻す
  // Hooks の宣言
  const [currentUser, setCurrentUser] = useState(null);
  const [students, setStudents] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [tasks, setTasks] = useState({});
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [selectedTeacher, setSelectedTeacher] = useState(null);
  const [selectedSubject, setSelectedSubject] = useState(SUBJECTS[0]);
  const [isAddingTask, setIsAddingTask] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [sidebarTab, setSidebarTab] = useState('students');
  const [showBulkImport, setShowBulkImport] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(null);
  const [isNotesModalOpen, setIsNotesModalOpen] = useState(false);
  const [isMessaging, setIsMessaging] = useState(false);
  const [messagingPartner, setMessagingPartner] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState(FILTERS[0]);
  const { getUnreadCount } = useUnreadMessages(currentUser);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  
    // エクスポート関数の追加
const exportToExcel = () => {
  // マスター権限の確認
  if (!currentUser || !currentUser.isMaster) {
    alert('エクスポート権限がありません。マスター権限を持つ教師のみエクスポートできます。');
    return;
  }

  if (!selectedStudent) {
    alert('エクスポートする生徒を選択してください。');
    return;
  }

  if (!selectedSubject) { // 追加: selectedSubject の存在チェック
    alert('エクスポートする教科を選択してください。');
    return;
  }

  const key = `${selectedStudent.id}-${selectedSubject.id}`;
  const tasksByStudentAndSubject = tasks[key] || {};

  const exportData = [];

  Object.values(tasksByStudentAndSubject).forEach((task) => {
    Object.values(task.items || {}).forEach((item) => {
      exportData.push({
        生徒名: selectedStudent.name,
        学年:
          GRADES.find((g) => g.id === selectedStudent.grade)?.name ||
          selectedStudent.grade,
        教科: SUBJECTS.find((s) => s.id === selectedSubject.id)?.name || selectedSubject.id,
        課題名: task.title,
        チェック項目: item.text,
        課題期限: item.dueDate,
        確認テスト日時: item.testDate,
        確認テストスコア: item.testScore,
        チェックボックス: item.checked ? 'true' : 'false',
      });
    });
  });

  if (exportData.length === 0) {
    alert('対象生徒に登録されている課題がありません。');
    return;
  }

  // エクセルワークブックとワークシートの作成
  const worksheet = XLSX.utils.json_to_sheet(exportData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Tasks');

  // エクセルファイルのバイナリデータを取得
  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

  // Blobオブジェクトの作成
  const data = new Blob([excelBuffer], { type: 'application/octet-stream' });

  // ファイルのダウンロード
  saveAs(
    data,
    `${selectedStudent.name}_tasks_${new Date().toISOString().slice(0, 10)}.xlsx`
  );
};
  
  
  
  
  
  
  

  // ハンドラー関数
  const handleOpenMessaging = (partner) => {
    setMessagingPartner(partner);
    setIsMessaging(true);
  };

  const handleCloseMessaging = () => {
    setIsMessaging(false);
    setMessagingPartner(null);
  };

  const handleOpenNotesModal = () => {
    console.log('メモモーダルを開きます'); // デバッグ用
    setIsNotesModalOpen(true);
  };

  const handleCloseNotesModal = () => {
    setIsNotesModalOpen(false);
  };

  const handleBulkImport = async (processedData) => {
    try {
      const updates = {};
      for (const [key, data] of Object.entries(processedData)) {
        updates[`tasks/${key}`] = {
          ...tasks[key],
          ...data.tasks,
        };
      }
      await update(ref(database), updates);
      setShowBulkImport(false);
    } catch (error) {
      console.error('Error saving bulk tasks:', error);
      alert('一括登録中にエラーが発生しました。再度お試しください。');
    }
  };

  const handleAddTeacher = async ({ name, username, password, isMaster }) => {
    try {
      if (isMaster && teachers.some(teacher => teacher.isMaster)) {
        alert('既にマスターティーチャーが存在します。');
        return;
      }
      const newTeacherRef = push(ref(database, 'teachers'));
      const newTeacher = {
        id: newTeacherRef.key,
        name,
        username,
        password,
        isMaster,
      };
      await set(newTeacherRef, newTeacher);
      console.log('新しい教師が追加されました');
    } catch (error) {
      console.error('教師の追加に失敗しました:', error);
      alert('教師の追加に失敗しました。再度お試しください。');
    }
  };

  const handleUpdateTeacher = async (updatedTeacher) => {
    try {
      const teacherRef = ref(database, `teachers/${updatedTeacher.id}`);
      await set(teacherRef, updatedTeacher);
      console.log('教師情報が更新されました');
    } catch (error) {
      console.error('教師情報の更新に失敗しました:', error);
      alert('教師情報の更新に失敗しました。再度お試しください。');
    }
  };

  const handleDeleteTeacher = async (id) => {
    try {
      await remove(ref(database, `teachers/${id}`));
      console.log('教師が削除されました');
      if (selectedTeacher?.id === id) {
        setSelectedTeacher(null);
      }
    } catch (error) {
      console.error('教師の削除に失敗しました:', error);
      alert('教師の削除に失敗しました。再度お試しください。');
    }
  };

  // 課題の並び替えを処理する関数
  const handleDragEnd = async (result) => {
    const { destination, source, draggableId, type } = result;
    
    if (!destination) return;
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) return;

    const studentId = getStudentId();
    if (!studentId) return;
    const key = `${studentId}-${selectedSubject.id}`;

    try {
      if (type === 'task') {
        // タスクの並び替え
        const taskList = tasks[key]
          ? Object.values(tasks[key]).sort((a, b) => (a.order || 0) - (b.order || 0))
          : [];

        const reorderedTasks = Array.from(taskList);
        const [movedTask] = reorderedTasks.splice(source.index, 1);
        reorderedTasks.splice(destination.index, 0, movedTask);

        const updates = {};
        reorderedTasks.forEach((task, index) => {
          updates[`tasks/${key}/${task.id}/order`] = index;
        });

        await update(ref(database), updates);
        console.log('タスクの順序が更新されました');
      } else if (type.startsWith('taskItems-')) {
        // チェック項目の並び替え
        const taskId = type.replace('taskItems-', '');
        const task = tasks[key]?.[taskId];
        if (!task) return;

        const itemList = Object.entries(task.items || {})
          .sort(([, a], [, b]) => (a.order || 0) - (b.order || 0));

        const reorderedItems = Array.from(itemList);
        const [movedItem] = reorderedItems.splice(source.index, 1);
        reorderedItems.splice(destination.index, 0, movedItem);

        const updates = {};
        reorderedItems.forEach(([itemId], index) => {
          updates[`tasks/${key}/${taskId}/items/${itemId}/order`] = index;
        });

        await update(ref(database), updates);
        console.log('チェック項目の順序が更新されました');
      }
    } catch (error) {
      console.error('並び替えの更新に失敗しました:', error);
      alert('並び替えの更新に失敗しました。再度お試しください。');
    }
  };

  const handlePassFailItem = async (taskId, itemId, status) => {
    try {
      const studentId = getStudentId();
      const key = `${studentId}-${selectedSubject.id}`;
      const itemRef = ref(database, `tasks/${key}/${taskId}/items/${itemId}`);

      // 現在のアイテムのデータを取得
      const snapshot = await get(itemRef);
      const currentItem = snapshot.val();

      if (currentItem) {
        // ステータスの値を確認
        console.log('Received status:', status); // デバッグ用

        // 同じステータスがクリックされた場合は状態をリセット
        // それ以外の場合は新しい状態を設定
        const newStatus = currentItem.passStatus === status ? null : status;

        // FirebaseでpassStatusを更新
        await update(itemRef, { passStatus: newStatus });
        
        console.log(`合否判定を${newStatus || 'リセット'}に更新しました`);
      } else {
        console.error('指定された項目が見つかりません');
        throw new Error('Item not found');
      }
    } catch (error) {
      console.error('合否判定の更新に失敗しました:', error);
      alert('合否判定の更新に失敗しました。再度お試しください。');
    }
  };

  const handleLogin = (username, password) => {
    // データがまだロードされていない場合は処理を中断
    if (!isDataLoaded) {
      alert('データを読み込み中です。しばらくお待ちください。');
      return;
    }

    // 教師の認証
    const teacher = teachers.find(
      (t) => t.username === username && t.password === password
    );
    if (teacher) {
      setCurrentUser({
        ...teacher,
        role: 'teacher',
        isMaster: teacher.isMaster || false,
      });
      setSelectedStudent(null);
      setSelectedTeacher(null);
      setIsSidebarOpen(true); // ログイン時にサイドバーを開く
      return;
    }

    // 生徒の認証
    const student = students.find(
      (s) => s.username === username && s.password === password
    );
    if (student) {
      setCurrentUser({ ...student, role: 'student' });
      setSelectedStudent(null);
      setSelectedTeacher(null);
      setIsSidebarOpen(false); // 生徒の場合はサイドバーを閉じる
    } else {
      alert('ユーザー名またはパスワードが間違っています');
    }
  };

  const getStudentId = () => {
    if (currentUser && currentUser.role === 'student') {
      return currentUser.id;
    } else if (selectedStudent) {
      return selectedStudent.id;
    }
    return null;
  };

  // データの取得
  useEffect(() => {
    // 教師データの取得
    const teachersRef = ref(database, 'teachers');
    const unsubscribeTeachers = onValue(teachersRef, async (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const teacherList = Object.entries(data).map(([key, value]) => ({
          id: key,
          ...value,
        }));
        setTeachers(teacherList);
      } else {
        setTeachers([]);
        // デフォルトのマスターティーチャーを作成
        const newTeacherRef = push(ref(database, 'teachers'));
        const newTeacher = {
          id: newTeacherRef.key,
          name: 'マスター教師',
          username: 'master',
          password: 'password',
          isMaster: true,
        };
        await set(newTeacherRef, newTeacher);
        setTeachers([newTeacher]); // 新しく作成した教師を状態に追加
      }

      // データ取得完了後に状態を更新
      setIsDataLoaded(true);
    });

    // 生徒データの取得
    const studentsRef = ref(database, 'students');
    const unsubscribeStudents = onValue(studentsRef, (snapshot) => {
      const data = snapshot.val();
      const studentList = data ? Object.values(data) : [];
      setStudents(studentList);
    });

    // クリーンアップ
    return () => {
      unsubscribeTeachers();
      unsubscribeStudents();
    };
  }, []);

  // タスクデータの取得
  useEffect(() => {
    const studentId = getStudentId();
    if (!studentId || !selectedSubject.id) return;

    const key = `${studentId}-${selectedSubject.id}`;
    const tasksRef = ref(database, `tasks/${key}`);
    const unsubscribeTasks = onValue(tasksRef, (snapshot) => {
      const data = snapshot.val();
      setTasks((prevTasks) => ({
        ...prevTasks,
        [key]: data || {},
      }));
    });

    return () => unsubscribeTasks();
  }, [selectedStudent, selectedSubject, currentUser]);

  // ハンドラー関数は一度だけ宣言
  const handleDeleteItem = async (taskId, itemId) => {
    const studentId = getStudentId();
    const key = `${studentId}-${selectedSubject.id}`;
    const itemRef = ref(database, `tasks/${key}/${taskId}/items/${itemId}`);
    try {
      await remove(itemRef);
      console.log('チェック項目が削除されました');
    } catch (error) {
      console.error('チェック項目の削除に失敗しました:', error);
      alert('チェック項目の削除に失敗しました。再度お試しください。');
    }
  };

  const handleUpdateItem = async (taskId, itemId, updatedItem) => {
    try {
      const studentId = getStudentId();
      const key = `${studentId}-${selectedSubject.id}`;
      const itemRef = ref(database, `tasks/${key}/${taskId}/items/${itemId}`);
      // update を使用
      await update(itemRef, updatedItem);
      console.log('チェック項目が更新されました');
    } catch (error) {
      console.error('チェック項目の更新に失敗しました:', error);
      alert('チェック項目の更新に失敗しました。再度お試しください。');
    }
  };

  // ローディング状態とログイン状態の管理
  if (!isDataLoaded) {
    return <div className="text-purple-700">データを読み込み中...</div>;
  }

  if (!currentUser) {
    return <Login onLogin={handleLogin} />;
  }

  // 残りのコンポーネントのレンダリング処理
  return (
    <div className="bg-yellow-50 min-h-screen">
      <DragDropContext onDragEnd={handleDragEnd}>
        <Navbar
          currentUser={currentUser}
          selectedStudent={selectedStudent}
          selectedTeacher={selectedTeacher}
          onLogout={() => {
            setCurrentUser(null);
            setSelectedStudent(null);
            setSelectedTeacher(null);
          }}
          onToggleSidebar={() => setIsSidebarOpen(true)}
          onOpenMessaging={handleOpenMessaging}
          teachers={teachers}
          onOpenNotes={handleOpenNotesModal}
          onExport={exportToExcel} 
        />

        <div className="max-w-screen-xl mx-auto px-4 md:flex md:gap-6">
          {/* サイドバー */}
          {(currentUser.role === 'teacher' || currentUser.isMaster) && (
            <>
              {/* デスクトップ表示 */}
              <div className="hidden md:block md:w-72">
                {currentUser.isMaster ? (
                  <>
                    {/* タブ切り替え */}
                    <div className="flex mb-4">
                      <button
                        onClick={() => setSidebarTab('students')}
                        className={`flex-1 py-2 text-center ${
                          sidebarTab === 'students'
                            ? 'bg-purple-500 text-white'
                            : 'bg-purple-200 text-purple-700'
                        }`}
                        aria-label="生徒一覧タブ"
                      >
                        生徒一覧
                      </button>
                      <button
                        onClick={() => setSidebarTab('teachers')}
                        className={`flex-1 py-2 text-center ${
                          sidebarTab === 'teachers'
                            ? 'bg-purple-500 text-white'
                            : 'bg-purple-200 text-purple-700'
                        }`}
                        aria-label="教師一覧タブ"
                      >
                        教師一覧
                      </button>
                    </div>
                    {sidebarTab === 'students' ? (
                      <StudentList
                        students={students}
                        selectedStudent={selectedStudent}
                        onSelectStudent={setSelectedStudent}
                        onAddStudent={({ name, username, password, grade }) => {
                          const newStudentRef = push(ref(database, 'students'));
                          const newStudent = {
                            id: newStudentRef.key,
                            name,
                            username,
                            password,
                            grade,
                          };
                          set(newStudentRef, newStudent);
                        }}
                        onUpdateStudent={(updatedStudent) => {
                          set(ref(database, `students/${updatedStudent.id}`), updatedStudent);
                        }}
                        onDeleteStudent={(id) => {
                          remove(ref(database, `students/${id}`));
                          if (selectedStudent?.id === id) {
                            setSelectedStudent(null);
                          }
                        }}
                        currentUser={currentUser}
                        onOpenMessaging={handleOpenMessaging}
                      />
                    ) : (
                      <TeacherList
                        teachers={teachers}
                        selectedTeacher={selectedTeacher}
                        onSelectTeacher={setSelectedTeacher}
                        onAddTeacher={handleAddTeacher}
                        onUpdateTeacher={handleUpdateTeacher}
                        onDeleteTeacher={handleDeleteTeacher}
                        onClose={() => setIsSidebarOpen(false)}
                        currentUser={currentUser}
                        onOpenMessaging={handleOpenMessaging}
                      />
                    )}
                  </>
                ) : (
                  <StudentList
                    students={students}
                    selectedStudent={selectedStudent}
                    onSelectStudent={setSelectedStudent}
                    onAddStudent={({ name, username, password, grade }) => {
                      const newStudentRef = push(ref(database, 'students'));
                      const newStudent = {
                        id: newStudentRef.key,
                        name,
                        username,
                        password,
                        grade,
                      };
                      set(newStudentRef, newStudent);
                    }}
                    onUpdateStudent={(updatedStudent) => {
                      set(ref(database, `students/${updatedStudent.id}`), updatedStudent);
                    }}
                    onDeleteStudent={(id) => {
                      remove(ref(database, `students/${id}`));
                      if (selectedStudent?.id === id) {
                        setSelectedStudent(null);
                      }
                    }}
                    currentUser={currentUser}
                    onOpenMessaging={handleOpenMessaging}
                  />
                )}
              </div>

              {/* モバイル表示 */}
              {isSidebarOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 z-50">
                  <div className="fixed inset-y-0 left-0 w-72 bg-yellow-50 shadow-lg z-50">
                    {currentUser.isMaster ? (
                      <>
                        {/* タブ切り替え */}
                        <div className="flex mb-4">
                          <button
                            onClick={() => setSidebarTab('students')}
                            className={`flex-1 py-2 text-center ${
                              sidebarTab === 'students'
                                ? 'bg-purple-500 text-white'
                                : 'bg-purple-200 text-purple-700'
                            }`}
                            aria-label="生徒一覧タブ"
                          >
                            生徒一覧
                          </button>
                          <button
                            onClick={() => setSidebarTab('teachers')}
                            className={`flex-1 py-2 text-center ${
                              sidebarTab === 'teachers'
                                ? 'bg-purple-500 text-white'
                                : 'bg-purple-200 text-purple-700'
                            }`}
                            aria-label="教師一覧タブ"
                          >
                            教師一覧
                          </button>
                        </div>
                        {sidebarTab === 'students' ? (
                          <StudentList
                            students={students}
                            selectedStudent={selectedStudent}
                            onSelectStudent={(student) => {
                              setSelectedStudent(student);
                              setIsSidebarOpen(false);
                            }}
                            onAddStudent={({ name, username, password, grade }) => {
                              const newStudentRef = push(ref(database, 'students'));
                              const newStudent = {
                                id: newStudentRef.key,
                                name,
                                username,
                                password,
                                grade,
                              };
                              set(newStudentRef, newStudent);
                            }}
                            onUpdateStudent={(updatedStudent) => {
                              set(ref(database, `students/${updatedStudent.id}`), updatedStudent);
                            }}
                            onDeleteStudent={(id) => {
                              remove(ref(database, `students/${id}`));
                              if (selectedStudent?.id === id) {
                                setSelectedStudent(null);
                              }
                            }}
                            onClose={() => setIsSidebarOpen(false)}
                            currentUser={currentUser}
                            onOpenMessaging={handleOpenMessaging}
                          />
                        ) : (
                          <TeacherList
                            teachers={teachers}
                            selectedTeacher={selectedTeacher}
                            onSelectTeacher={(teacher) => {
                              setSelectedTeacher(teacher);
                              setIsSidebarOpen(false);
                            }}
                            onAddTeacher={handleAddTeacher}
                            onUpdateTeacher={handleUpdateTeacher}
                            onDeleteTeacher={handleDeleteTeacher}
                            onClose={() => setIsSidebarOpen(false)}
                            currentUser={currentUser}
                            onOpenMessaging={handleOpenMessaging}
                          />
                        )}
                      </>
                    ) : (
                      <StudentList
                        students={students}
                        selectedStudent={selectedStudent}
                        onSelectStudent={(student) => {
                          setSelectedStudent(student);
                          setIsSidebarOpen(false);
                        }}
                        onAddStudent={({ name, username, password, grade }) => {
                          const newStudentRef = push(ref(database, 'students'));
                          const newStudent = {
                            id: newStudentRef.key,
                            name,
                            username,
                            password,
                            grade,
                          };
                          set(newStudentRef, newStudent);
                        }}
                        onUpdateStudent={(updatedStudent) => {
                          set(ref(database, `students/${updatedStudent.id}`), updatedStudent);
                        }}
                        onDeleteStudent={(id) => {
                          remove(ref(database, `students/${id}`));
                          if (selectedStudent?.id === id) {
                            setSelectedStudent(null);
                          }
                        }}
                        onClose={() => setIsSidebarOpen(false)}
                        currentUser={currentUser}
                        onOpenMessaging={handleOpenMessaging}
                      />
                    )}
                  </div>
                  <div
                    className="fixed inset-0 z-40"
                    onClick={() => setIsSidebarOpen(false)}
                    aria-hidden="true"
                  ></div>
                </div>
              )}
            </>
          )}

          {/* メインコンテンツ */}
          <div className="flex-1">
            {(currentUser.role === 'student' ||
              selectedStudent ||
              selectedTeacher) && (
              <>
                <SubjectTabs
                  subjects={SUBJECTS}
                  selectedSubject={selectedSubject}
                  onSelectSubject={setSelectedSubject}
                />

                {currentUser.isMaster && selectedStudent && (
  <div className="mb-6">
    <button
      onClick={() => setShowBulkImport(!showBulkImport)}
      className="w-full py-4 border-2 border-dashed border-purple-300 rounded-lg hover:border-purple-500 hover:text-purple-500 flex items-center justify-center gap-2 transition-colors duration-200"
      aria-label="課題を一括登録"
    >
      一括登録
    </button>

    {/* BulkTaskImport コンポーネントを条件付きでレンダリング */}
    {showBulkImport && (
      <BulkTaskImport
        students={students}
        onImport={handleBulkImport}
        selectedStudent={selectedStudent}
        selectedSubject={selectedSubject}
        tasksByStudentAndSubject={tasks[`${selectedStudent.id}-${selectedSubject.id}`]}
      />
    )}
  </div>
)}

                <div className="space-y-6">
                  {(() => {
                    const studentId = getStudentId();
                    if (!studentId) return null;
                    const key = `${studentId}-${selectedSubject.id}`;

                    // taskList の定義をコンポーネント関数内で行う
                    const taskList = tasks[key]
                      ? Object.values(tasks[key]).sort((a, b) => (a.order || 0) - (b.order || 0))
                      : [];

                    // フィルタリングロジックを追加
                    const filteredTaskList = taskList.filter((task) => {
                      // フィルターに応じて課題を絞り込む
                      if (selectedFilter.id === 'all') {
                        return true; // 全て表示
                      }

                      // 課題内の各チェック項目を確認
                      const items = Object.values(task.items || {});
                      if (selectedFilter.id === 'dueAndNotConfirmed') {
                        // 宿題期限が設定されており、先生確認がチェックされていないもの
                        return items.some((item) => item.dueDate && !item.teacherCheck);
                      }

                      if (selectedFilter.id === 'noTestDate') {
                        // 確認テストの日時が未設定のもの
                        return items.some((item) => !item.testDate);
                      }

                      if (selectedFilter.id === 'failedConfirmation') {
                        // 最終確認が不合格のもの
                        return items.some((item) => item.passStatus === 'fail');
                      }

                      return false; // フィルター条件に一致しない場合は除外
                    });

                    return (
                      <Droppable droppableId="tasksDroppable" type="task">
                        {(provided) => (
                          <div
                            className="space-y-6"
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            {filteredTaskList.map((task, index) => (
                              <Draggable
                                key={task.id}
                                draggableId={`task-${task.id}`}
                                index={index}
                                isDragDisabled={!currentUser.isMaster} // マスター教師以外はドラッグ不可
                              >
                                {(provided) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                  >
                                    <TaskCard
                                      task={task}
                                      isTeacher={
                                        currentUser.role === 'teacher' || currentUser.isMaster
                                      }
                                      onDeleteTask={() => {
                                        setConfirmDialog({
                                          title: '課題の削除',
                                          message: 'この課題を削除してもよろしいですか？',
                                          onConfirm: () => {
                                            remove(ref(database, `tasks/${key}/${task.id}`));
                                            setConfirmDialog(null);
                                          },
                                          onCancel: () => setConfirmDialog(null),
                                        });
                                      }}
                                      onUpdateTask={(updatedTask) => {
                                        set(ref(database, `tasks/${key}/${updatedTask.id}`), updatedTask);
                                      }}
                                      onCheckItem={(taskId, itemId) => {
                                        const itemRef = ref(
                                          database,
                                          `tasks/${key}/${taskId}/items/${itemId}`
                                        );
                                        const currentItem =
                                          tasks[key]?.[taskId]?.items?.[itemId];
                                        if (currentItem) {
                                          const currentChecked = currentItem.checked || false;
                                          update(itemRef, { checked: !currentChecked })
                                            .then(() => {
                                              console.log('Checked status updated successfully');
                                            })
                                            .catch((error) => {
                                              console.error(
                                                'Error updating checked status:',
                                                error
                                              );
                                              alert(
                                                'チェック状態の更新に失敗しました。再度お試しください。'
                                              );
                                            });
                                        }
                                      }}
                                      onTeacherCheckItem={(taskId, itemId, status) => {
                                        const itemRef = ref(
                                          database,
                                          `tasks/${key}/${taskId}/items/${itemId}`
                                        );
                                        update(itemRef, { teacherCheck: status })
                                          .then(() => {
                                            console.log('教師確認が更新されました');
                                          })
                                          .catch((error) => {
                                            console.error(
                                              '教師確認の更新に失敗しました:',
                                              error
                                            );
                                            alert(
                                              '教師確認の更新に失敗しました。再度お試しください。'
                                            );
                                          });
                                      }}
                                      onPassFailItem={(taskId, itemId, status) => handlePassFailItem(taskId, itemId, status)}
                                      onUpdateItem={(taskId, itemId, updatedItem) => handleUpdateItem(taskId, itemId, updatedItem)}
                                      onDeleteItem={(taskId, itemId) => handleDeleteItem(taskId, itemId)}
                                      getStudentId={getStudentId}
                                      selectedSubject={selectedSubject}
                                      currentUser={currentUser} // 追加: currentUser を渡す
                                    />
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    );
                  })()}

                  {(currentUser.role === 'teacher' || currentUser.isMaster) &&
                    selectedStudent &&
                    (isAddingTask ? (
                      <AddTaskForm
                        // 課題追加ハンドラー
                        onAddTask={(newTask) => {
                          const key = `${selectedStudent.id}-${selectedSubject.id}`;
                          const taskId = uuidv4();
                          const taskWithId = {
                            ...newTask,
                            id: taskId,
                          };
                          set(ref(database, `tasks/${key}/${taskWithId.id}`), taskWithId);
                          setIsAddingTask(false);
                        }}
                        onCancel={() => setIsAddingTask(false)}
                      />
                    ) : (
                      <button
                        onClick={() => setIsAddingTask(true)}
                        className="w-full py-4 border-2 border-dashed border-purple-300 rounded-lg hover:border-purple-500 hover:text-purple-500 flex items-center justify-center gap-2 transition-colors duration-200"
                        aria-label="新しい課題を追加"
                      >
                        {/* アイコンを追加する場合は適切なアイコンをインポートしてください */}
                        新しい課題を追加
                      </button>
                    ))}
                </div>

                {/* 教師のみメモモーダルを表示 */}
                {isNotesModalOpen && (
                  <TeacherNotesModal
                    selectedStudent={selectedStudent}
                    currentUser={currentUser}
                    onClose={handleCloseNotesModal}
                  />
                )}
              </>
            )}
          </div>
        </div>

        {confirmDialog && (
          <ConfirmDialog
            title={confirmDialog.title}
            message={confirmDialog.message}
            onConfirm={confirmDialog.onConfirm}
            onCancel={confirmDialog.onCancel}
          />
        )}

        {/* メッセージングモーダルの表示 */}
        {isMessaging && (
          <MessagingModal
            currentUser={currentUser}
            partner={messagingPartner}
            onClose={handleCloseMessaging}
          />
        )}
      </DragDropContext>
    </div>
  );
};

SubjectTaskBoard.propTypes = {
  // 必要に応じてプロパティの型定義を追加
};

export default SubjectTaskBoard;